import { buildApiCall } from '@he-novation/config/apis/api-fetch';
import {
    PluginConfigurationCreationSchema,
    PluginConfigurationSchema
} from '@he-novation/config/types/payloads/plugin.payload';
import { PluginConfigurationType } from '@he-novation/config/types/plugin.types';
import { API_PLUGIN, API_PLUGIN_AUTH_URL } from '../../config/apis/plugin-api';

export const asyncPluginConfigurationCreate = async (body: PluginConfigurationCreationSchema) =>
    buildApiCall(API_PLUGIN.POST)({
        body
    });

export const asyncPluginConfigurationUpdate = (
    pluginUuid: string,
    body: PluginConfigurationSchema
): Promise<PluginConfigurationType> =>
    buildApiCall(API_PLUGIN.PATCH)({
        params: {
            pluginUuid
        },
        body
    });

export const asyncPluginConfigurationDelete = async (pluginUuid: string) =>
    buildApiCall(API_PLUGIN.DELETE)({
        params: {
            pluginUuid
        }
    });

export const asyncPluginAuthUrlFetch = async (type: string) =>
    buildApiCall(API_PLUGIN_AUTH_URL.GET)({ params: { type } }).then(
        (r: { authUrl: string; state?: string }) => {
            if (r?.authUrl) window.location.href = r.authUrl;
            return r;
        }
    );
