import { z } from 'zod';
import { ROLES } from '../../constants/projects.constants';
import { TEAM_STATUS } from '../../constants/teams.constants';
import { sanitizedString } from '@he-novation/utils/zod.utils';

export const teamParamsSchema = z.object({
    workspaceName: sanitizedString(),
    projectUuid: z.string().uuid(),
    teamUuid: z.string().uuid()
});

export const memberSchema = z.object({
    uuid: z.string().uuid().optional(),
    email: z.string().email(),
    projectRole: z.nativeEnum(ROLES),
    canDownload: z.boolean().optional(),
    canExport: z.boolean().optional()
});

export type Member = z.infer<typeof memberSchema>;
export type MemberWithUuid = Omit<Member, 'uuid'> & { uuid: string };
export const teamInviteBody = z.object({
    projectUuid: z.string().uuid(),
    members: z.array(memberSchema),
    message: sanitizedString({ optional: true }),
    castTeamAccess: z.boolean().optional(),
    teamColor: z.string().optional()
});

export type TeamInviteBody = z.infer<typeof teamInviteBody>;

export const teamStatusBody = z.object({
    status: z.nativeEnum(TEAM_STATUS),
    projectUuid: z.string().uuid()
});
export type TeamStatusBody = z.infer<typeof teamStatusBody>;

export const teamSpecificSchema = z.object({
    teamUuid: z.string().uuid()
});

export type TeamSpecificSchema = z.infer<typeof teamSpecificSchema>;
export const teamNameSchema = z.object({
    teamName: z.string()
});

export type TeamNameSchema = z.infer<typeof teamNameSchema>;

export const teamUpdateBody = z.object({
    name: z.string(),
    castTeamAccess: z.boolean().optional(),
    color: z.string().optional()
});

export type TeamUpdateBody = z.infer<typeof teamUpdateBody>;

export const teamPostBody = z.object({
    name: z.string(),
    color: z.string().optional(),
    castTeamAccess: z.boolean().optional()
});

export const teamPutBody = z.object({
    name: z.string().optional(),
    color: z.string().optional(),
    castTeamAccess: z.boolean().optional(),
    status: z.nativeEnum(TEAM_STATUS).optional()
});

export const teamUsersPostBody = z.object({
    members: z.array(memberSchema),
    message: sanitizedString({ optional: true })
});
export const teamUserParamsSchema = z.object({
    workspaceName: sanitizedString(),
    projectUuid: z.string().uuid(),
    teamUuid: z.string().uuid(),
    userUuid: z.string().uuid()
});

export const teamUserPatchBody = z.object({
    role: z.nativeEnum(ROLES).optional(),
    download: z.boolean().optional(),
    metadata: z
        .object({
            favorite: z.boolean().optional()
        })
        .optional()
});
