import { assetSchema } from './asset.types';
import { baseClientLabelSchema } from './clientLabel.types';
import { z } from 'zod';

export const itemSchema = z.object({
    uuid: z.string().uuid(),
    name: z.string(),
    description: z.string().optional().nullable(),
    category: z.string().optional().nullable(),
    serialNumber: z.string().optional().nullable(),
    labels: z.array(baseClientLabelSchema),
    pictureAsset: assetSchema.optional().nullable(),
    picture: z.string().optional().nullable()
});

export type Item = z.infer<typeof itemSchema>;

export const itemKitSchema = z.object({
    uuid: z.string().uuid(),
    name: z.string(),
    items: z.array(itemSchema)
});

export type ItemKit = z.infer<typeof itemKitSchema>;

export const itemWithEventsSchema = itemSchema.extend({
    events: z.array(
        z.object({
            uuid: z.string().uuid(),
            startDate: z.date(),
            endDate: z.date()
        })
    )
});

export type ItemWithEvents = z.infer<typeof itemWithEventsSchema>;

export type ItemsState = {
    isLoaded: boolean;
    list: Item[];
    itemsWithProjectItemsList: ItemWithEvents[];
    kits: {
        isLoaded: boolean;
        list: ItemKit[];
    };
};
