import { Group } from '../paths/herawApiPathGroups';
import { ApiMethod } from '../types/api.types';
import { oaFileSpecificParams } from '../types/open-api/file.open-api.types';
import { castFileVersionParamsSchema } from '../types/payloads/cast.payload';
import { fileParamsSchema, fileVersionParamsSchema } from '../types/payloads/file.payload';
import { passwordQuery } from '../types/payloads/folder.payload';
import { z } from 'zod';
import {
    commentCreateBody,
    commentEditBody,
    commentParamsSchema,
    noteAttachmentCreateBody,
    noteCreateBody,
    noteParamsSchema
} from '../types/payloads/note.payload';
import { workspaceParamsSchema } from '../types/payloads/workspace.payload';
import {
    oaCommentCreateBody,
    oaNoteCreateBody,
    oaNoteSchema,
    oaNoteSpecificParams
} from '../types/open-api/note.open-api.types';

export const API_NOTE = {
    LIST: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/files/:fileUuid/notes',
        handlerMethod: 'listNotes' as 'listNotes',
        isPublic: true,
        zod: {
            params: fileParamsSchema
        }
    },
    LIST_OA_DEPRECATED: {
        method: ApiMethod.GET,
        path: '/public/v1/file/:fileUuid/v/:fileVersion/notes',
        handlerMethod: 'listNotes' as 'listNotes',
        zod: {
            params: oaFileSpecificParams
        },
        publicApi: 'deprecated' as 'deprecated',
        description: 'Get notes for a file',
        group: Group.Notes,
        responses: {
            200: {
                description: 'Notes retrieved',
                schema: z.record(z.string().uuid(), oaNoteSchema)
            }
        }
    },
    POST: {
        method: ApiMethod.POST,
        path: '/workspaces/:workspaceName/notes',
        handlerMethod: 'postNote' as 'postNote',
        zod: {
            params: workspaceParamsSchema,
            body: noteCreateBody,
            query: passwordQuery
        }
    },
    POST_OA_DEPRECATED: {
        method: ApiMethod.POST,
        path: '/public/v1/file/:fileUuid/v/:fileVersion/note',
        handlerMethod: 'createSimpleNote' as 'createSimpleNote',
        publicApi: 'deprecated' as 'deprecated',
        description: 'Creates a simple note and associated comment',
        zod: {
            params: oaFileSpecificParams,
            body: oaNoteCreateBody
        },
        responses: {
            201: {
                description: 'Note created',
                schema: oaNoteSchema
            },
            404: {
                description: 'File not found or user not authorized to add note to file'
            }
        },
        group: Group.Notes
    },
    DELETE: {
        method: ApiMethod.DELETE,
        path: '/workspaces/:workspaceName/notes/:noteUuid',
        handlerMethod: 'deleteNote' as 'deleteNote',
        zod: {
            params: noteParamsSchema
        }
    },
    AVID_LIST: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/notes/file/:fileUuid/:fileVersion/avid-summary',
        handlerMethod: 'getAvidSummary' as 'getAvidSummary',
        contentType: 'text/plain',
        zod: {
            params: fileVersionParamsSchema
        }
    }
};

export const API_NOTE_COMMENT = {
    POST: {
        method: ApiMethod.POST,
        path: '/workspaces/:workspaceName/notes/:noteUuid/comments',
        handlerMethod: 'postComment' as 'postComment',
        isPublic: true,
        zod: {
            params: noteParamsSchema,
            body: commentCreateBody,
            query: passwordQuery
        }
    },
    POST_OA_DEPRECATED: {
        method: ApiMethod.POST,
        path: '/public/v1/note/:noteUuid/comment',
        handlerMethod: 'createSimpleComment' as 'createSimpleComment',
        publicApi: 'deprecated' as 'deprecated',
        description: 'Creates a comment associated with a note',
        zod: {
            params: oaNoteSpecificParams,
            body: oaCommentCreateBody
        },
        responses: {
            201: {
                description: 'Note created',
                schema: oaNoteSchema
            },
            404: {
                description: 'File not found or user not authorized to add note to file'
            }
        },
        group: Group.Notes
    },
    PATCH: {
        method: ApiMethod.PATCH,
        path: '/workspaces/:workspaceName/notes/:noteUuid/comment/:commentUuid',
        handlerMethod: 'updateComment' as 'updateComment',
        zod: {
            params: commentParamsSchema,
            body: commentEditBody
        }
    },
    DELETE: {
        method: ApiMethod.DELETE,
        path: '/workspaces/:workspaceName/notes/:noteUuid/comment/:commentUuid',
        handlerMethod: 'deleteComment' as 'deleteComment',
        zod: {
            params: commentParamsSchema
        }
    }
};

export const API_NOTE_COMMENT_ATTACHMENT = {
    POST: {
        method: ApiMethod.POST,
        path: '/workspaces/:workspaceName/notes/:noteUuid/comment/:commentUuid/attachment',
        handlerMethod: 'postCommentAttachment' as 'postCommentAttachment',
        isPublic: true as true,
        zod: {
            params: commentParamsSchema,
            body: noteAttachmentCreateBody
        }
    }
};

export const API_CAST_NOTE = {
    LIST: {
        method: ApiMethod.GET,
        path: '/casts/:castUid/files/:castFileUuid/versions/:fileVersion/notes',
        handlerMethod: 'listCastNotes' as 'listCastNotes',
        isPublic: true,
        zod: {
            params: castFileVersionParamsSchema
        }
    },
    POST: {
        method: ApiMethod.POST,
        path: '/workspaces/:workspaceName/cast-notes',
        handlerMethod: 'postNote' as 'postNote',
        isPublic: true,
        zod: {
            params: workspaceParamsSchema,
            body: noteCreateBody,
            query: passwordQuery
        }
    }
};
