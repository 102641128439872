import { response } from 'express';
import { ApiMethod } from '../types/api.types';
import { assetSchema } from '../types/asset.types';
import {
    assetStatusBody,
    assetUploadAbortBody,
    assetUploadCompleteBody,
    assetUuidSchema,
    migrateFolderAssetsBody
} from '../types/payloads/asset.payload';
import { z } from 'zod';
import {
    oaAssetSchema,
    oaAssetUploadAbortBody,
    oaAssetUploadCommandParam,
    oaAssetUploadCommandParamDeprecated,
    oaAssetUploadCompleteBody
} from '../types/open-api/asset.open-api.types';
import { bucketSchema } from '../types/bucket.types';
import { workspaceParamsSchema } from '../types/payloads/workspace.payload';
import { Group } from '../paths/herawApiPathGroups';

export const API_ASSET = {
    GET: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/assets/:assetUuid',
        handlerMethod: 'getAsset' as 'getAsset',
        zod: {
            params: assetUuidSchema
        },
        responses: {
            200: {
                description: 'Asset',
                schema: assetSchema
            }
        }
    },
    SIGNED_GET: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/assets/:assetUuid/sign',
        handlerMethod: 'getSignedUrl' as 'getSignedUrl',
        zod: {
            params: assetUuidSchema
        },
        responses: {
            200: {
                description: 'Signed URL',
                schema: z.object({
                    url: z.string()
                })
            }
        }
    },
    STATUS_PUT: {
        method: ApiMethod.PUT,
        path: '/workspaces/:workspaceName/assets/:assetUuid/status',
        handlerMethod: 'updateAssetStatus' as 'updateAssetStatus',
        zod: {
            params: assetUuidSchema,
            body: assetStatusBody
        },
        responses: {
            200: {
                description: 'Asset',
                schema: assetSchema
            }
        }
    },
    MIGRATE_FOLDER_ASSETS_PUT: {
        method: ApiMethod.PUT,
        path: '/workspaces/:workspaceName/assets/migrate/folder',
        handlerMethod: 'migrateFolderAssetsToBucket' as 'migrateFolderAssetsToBucket',
        superAdminOnly: true,
        zod: {
            params: workspaceParamsSchema,
            body: migrateFolderAssetsBody
        }
    }
};

export const API_ASSET_UPLOAD = {
    PATCH: {
        description: 'Completes a multipart upload',
        method: ApiMethod.PATCH,
        path: '/workspaces/:workspaceName/assets/:assetUuid/upload',
        handlerMethod: 'completeMultiPartUpload' as 'completeMultiPartUpload',
        isPublic: true,
        zod: {
            params: assetUuidSchema,
            body: assetUploadCompleteBody
        },
        responses: {
            200: {
                description: 'Asset uploaded',
                schema: assetSchema
            }
        }
    },
    PATCH_OA: {
        description: 'Completes a multipart upload',
        method: ApiMethod.PATCH,
        path: '/public/v1/workspaces/:workspaceName/assets/:assetUuid/upload',
        handlerMethod: 'completeMultiPartUpload' as 'completeMultiPartUpload',
        publicApi: true,
        group: Group.Files,
        zod: {
            params: oaAssetUploadCommandParam,
            body: oaAssetUploadCompleteBody
        },
        responses: {
            200: {
                description: 'Asset uploaded',
                schema: oaAssetSchema
            }
        }
    },
    PATCH_OA_DEPRECATED: {
        description: 'Completes a multipart upload',
        method: ApiMethod.PATCH,
        path: '/public/v1/asset/:assetUuid/upload',
        handlerMethod: 'completeMultiPartUpload' as 'completeMultiPartUpload',
        publicApi: true,
        group: Group.Files,
        zod: {
            params: oaAssetUploadCommandParamDeprecated,
            body: oaAssetUploadCompleteBody
        },
        responses: {
            200: {
                description: 'Asset uploaded',
                schema: oaAssetSchema
            }
        }
    },
    DELETE: {
        description: 'Aborts a multipart upload',
        method: ApiMethod.DELETE,
        path: '/workspaces/:workspaceName/assets/:assetUuid/upload',
        handlerMethod: 'abortMultiPartUpload' as 'abortMultiPartUpload',
        zod: {
            params: assetUuidSchema,
            body: assetUploadAbortBody
        },
        responses: {
            204: {
                description: 'Upload aborted'
            }
        }
    },
    DELETE_OA: {
        description: 'Aborts a multipart upload',
        method: ApiMethod.DELETE,
        path: '/public/v1/workspaces/:workspaceName/assets/:assetUuid/upload',
        handlerMethod: 'abortMultiPartUpload' as 'abortMultiPartUpload',
        publicApi: true,
        group: Group.Files,
        zod: {
            params: oaAssetUploadCommandParam,
            body: oaAssetUploadAbortBody
        },
        responses: {
            204: {
                description: 'Upload aborted'
            }
        }
    },
    DELETE_OA_DEPRECATED: {
        description: 'Aborts a multipart upload',
        method: ApiMethod.DELETE,
        path: '/public/v1/asset/:assetUuid/upload',
        handlerMethod: 'abortMultiPartUpload' as 'abortMultiPartUpload',
        publicApi: true,
        group: Group.Files,
        zod: {
            params: oaAssetUploadCommandParamDeprecated,
            body: oaAssetUploadAbortBody
        },
        responses: {
            204: {
                description: 'Upload aborted'
            }
        }
    }
};

export const API_BUCKET = {
    GET: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/buckets/',
        handlerMethod: 'getBuckets' as 'getBuckets',
        superAdminOnly: true,
        zod: {
            params: workspaceParamsSchema
        },
        responses: {
            200: {
                description: 'Buckets',
                schema: z.array(bucketSchema)
            }
        }
    }
};
