import { sanitizedString } from '@he-novation/utils/zod.utils';
import { z } from 'zod';

export const workspaceParamsSchema = z.object({
    workspaceName: sanitizedString({ min: 3, max: 255 })
});

export type WorkspaceParams = z.infer<typeof workspaceParamsSchema>;

export const oaWorkspaceParamsSchema = z.object({
    workspaceName: sanitizedString({ min: 3, max: 255 })
});
