import { z } from 'zod';
import { ApiMethod } from '../types/api.types';
import { entitySchema } from '../types/entity.types';
import {
    entityCreateBody,
    entityPictureUploadLinkBody,
    entityUpdateBody,
    workspaceNameAndEntityUuidSchema
} from '../types/payloads/entity.payload';
import { workspaceParamsSchema } from '../types/payloads/workspace.payload';
import { ENTITY } from '../paths/modals.constants';

export const ENTITY_DELETE = {
    method: ApiMethod.DELETE,
    path: '/workspaces/:workspaceName/entities/:entityUuid',
    handlerMethod: 'deleteEntity' as 'deleteEntity',
    zod: {
        params: workspaceNameAndEntityUuidSchema
    },
    responses: {
        204: {
            description: 'Entity deleted'
        }
    }
};

export const ENTITY_LIST = {
    method: ApiMethod.GET,
    path: '/workspaces/:workspaceName/entities',
    handlerMethod: 'findAll' as 'findAll',
    zod: {
        params: workspaceParamsSchema
    },
    responses: {
        200: {
            description: 'List of entities in workspace',
            schema: z.array(entitySchema)
        }
    }
};

export const ENTITY_POST = {
    method: ApiMethod.POST,
    path: '/workspaces/:workspaceName/entities',
    handlerMethod: 'createEntity' as 'createEntity',
    zod: {
        params: workspaceParamsSchema,
        body: entityCreateBody
    },
    responses: {
        200: {
            description: 'Created entity',
            schema: entitySchema
        }
    }
};

export const ENTITY_PUT = {
    method: ApiMethod.PUT,
    path: '/workspaces/:workspaceName/entities/:entityUuid',
    handlerMethod: 'updateEntity' as 'updateEntity',
    zod: {
        body: entityUpdateBody,
        params: workspaceNameAndEntityUuidSchema
    },
    responses: {
        200: {
            description: 'Updated entity',
            schema: entitySchema
        }
    }
};

export const ENTITY_PICTURE_UPLOAD_LINK_POST = {
    method: ApiMethod.POST,
    path: '/workspaces/:workspaceName/entities/:entityUuid/picture',
    handlerMethod: 'getCompanyPictureUploadLink' as 'getCompanyPictureUploadLink',
    zod: {
        params: workspaceNameAndEntityUuidSchema,
        body: entityPictureUploadLinkBody
    },
    responses: {
        200: {
            description: '',
            schema: z.object({
                link: z.string(),
                headers: z.record(z.string()),
                assetUuid: z.string()
            })
        }
    }
};
