import {
    fetchFileView as _fetchFileView,
    publicFileViewFetch as _publicFileViewFetch
} from '../content/contentAsync';

import { viewErrorAtom, viewErrorStore } from '$atoms/error-atoms';
import { asyncActionSuccess } from '$helpers/asyncAction';

export const CONTENT = 'CONTENT';
export const RESET_CONTENT = `${CONTENT}/RESET`;
export const FETCH_FILE_VIEW = `${CONTENT}/FETCH_FILE_VIEW`;
export const PUBLIC_FILE_VIEW_FETCH = `${CONTENT}/PUBLIC_FILE_VIEW_FETCH`;
export const FETCH_FOLDER_VIEW = `${CONTENT}/FETCH_FOLDER_VIEW`;
export const FETCH_SETTINGS_VIEW = `${CONTENT}/FETCH_SETTINGS_VIEW`;
export const FETCH_TRASH_VIEW = `${CONTENT}/FETCH_TRASH_VIEW`;

export const resetContent = (prevUrl, url) => ({
    type: RESET_CONTENT,
    prevUrl,
    url
});

export const fetchFileView =
    (workspaceName: string, { uuid, version, versionToCompare }, cb?: (state: any) => void) =>
    async (dispatch) => {
        dispatch({ type: FETCH_FILE_VIEW });
        try {
            const state = await _fetchFileView(workspaceName, {
                uuid,
                version,
                versionToCompare
            });

            dispatch({ type: asyncActionSuccess(FETCH_FILE_VIEW), ...state });
            if (typeof cb === 'function') cb(state);
        } catch (e) {
            console.error(e);
            if (e.code >= 400 || e.statusCode >= 400) {
                viewErrorStore.set(viewErrorAtom, 'File not found');
            }
        }
    };

export const publicFileViewFetch =
    (
        workspaceName: string,
        {
            uuid,
            version,
            public_password,
            onError,
            castUid,
            castFolderUuid,
            castFileUuid
        }: {
            uuid: string;
            version: number;
            public_password?: string | null;
            onError?: (e: any) => void;
            castUid?: string;
            castFolderUuid?: string | null;
            castFileUuid?: string;
        }
    ) =>
    async (dispatch) => {
        dispatch({ type: PUBLIC_FILE_VIEW_FETCH });
        try {
            const state = await _publicFileViewFetch(workspaceName, {
                uuid,
                version,
                public_password,
                castUid,
                castFolderUuid,
                castFileUuid
            });
            dispatch({ type: asyncActionSuccess(PUBLIC_FILE_VIEW_FETCH), ...state });
        } catch (e) {
            if (typeof onError === 'function') onError(e);
        }
    };
