import { sanitizedString } from '@he-novation/utils/zod.utils';
import { z } from 'zod';
export const workspaceNameAndEntityUuidSchema = z.object({
    workspaceName: sanitizedString({ min: 3, max: 255 }),
    entityUuid: z.string().uuid()
});

export const entityCreateBody = z.object({
    name: sanitizedString(),
    category: sanitizedString({ optional: true, nullable: true }),
    color: sanitizedString({ optional: true, nullable: true }),
    phone: sanitizedString({ optional: true, nullable: true }),
    address: z
        .object({
            street: sanitizedString({ optional: true }),
            city: sanitizedString({ optional: true }),
            country: sanitizedString({ optional: true }),
            zipCode: sanitizedString({ optional: true })
        })
        .optional()
        .nullable()
});

export type EntityCreateBody = z.infer<typeof entityCreateBody>;

export const entityUpdateBody = entityCreateBody.extend({
    name: sanitizedString({ optional: true })
});

export type EntityUpdateBody = z.infer<typeof entityUpdateBody>;

export const entityPictureUploadLinkBody = z.object({
    size: z.number(),
    contentType: z.string()
});
