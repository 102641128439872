import styles from './NoteList.module.css';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { projectTasksLink } from '@he-novation/config/paths/herawFrontUris';
import type { Note as NoteType } from '@he-novation/config/types/note.types';
import { TaskStatus } from '@he-novation/config/types/task.types';
import { noteTypeToI18n } from '@he-novation/config/utils/noteUtils';
import { Button } from '@he-novation/design-system/components/buttons/Button/Button';
import { Icon } from '@he-novation/design-system/components/graphics/Icon/Icon';
import { colorTextFaded } from '@he-novation/design-system/constants/constants.style';
import { remainingDaysToDateShortString } from '@he-novation/front-shared/utils/datetime';
import { getDisplayName } from '@he-novation/utils/user';
import cn from 'classnames';
import { useAtomValue } from 'jotai';
import { workspaceNameAtom } from '../../../../../atoms/workspace-atoms';
import { activeAssetSelector } from '../../../../../redux/content/file/fileSelectors';

import Chips from '$components/base/Chips/Chips';
import Link from '$components/router/Link';
import { FormComment } from '$components/SidePanel/SidePanelFile/SidePanelNotes/FormComment/FormComment';
import { NoteComment } from '$components/SidePanel/SidePanelFile/SidePanelNotes/NoteList/NoteComment';
import { TaskStatusSelector } from '$components/TaskStatusSelector/TaskStatusSelector';
import { useTranslate } from '$hooks/useTranslate';
import { setPage } from '$redux/content/file/fileActions';
import { updateTask } from '$redux/content/projects/projectsActions';
import { castFileUuidRouteSelector } from '$redux/route/routeSelectors';

type NoteProps = {
    item: NoteType;
    selected?: string | null;
    replying: string | null;
    setReplying: (replying: string | null) => void;
    creatingNote: boolean;
    editing: string | null;
    setEditing: (editing: string | null) => void;
};

export function Note({
    item,
    selected,
    replying,
    setReplying,
    creatingNote,
    editing,
    setEditing
}: NoteProps) {
    const comments = useMemo(
        () => item.comments.sort((a, b) => a.created.getTime() - b.created.getTime()),
        [item.comments]
    );
    const extraComments = comments.slice(1);
    const lastReply = extraComments[extraComments.length - 1];
    const [displayMore, setDisplayMore] = useState(false);
    const castFileUuid = useSelector(castFileUuidRouteSelector);
    const activeAsset = useSelector(activeAssetSelector);
    const workspaceName = useAtomValue(workspaceNameAtom);

    const { t } = useTranslate();
    const dispatch = useDispatch();

    const updateStatus = (status: TaskStatus) => {
        dispatch(updateTask(workspaceName, item.task!.project.uuid, item.task!.uuid, { status }));
    };

    useEffect(() => {
        if (selected === item.uuid && item.metadata && item.metadata.page !== undefined) {
            dispatch(setPage(item.metadata.page));
        }
    }, [selected, item]);

    return (
        <div
            className={cn(
                styles.note,
                creatingNote && styles.disabled,
                selected === item.uuid ? styles.selected : styles.disabled,
                replying && (replying === item.uuid ? styles.replying : styles.replyingToOther)
            )}
        >
            <header className={styles.header}>
                <div className={styles.headerLeft}>
                    {item.team && (
                        <Button
                            className={styles.team}
                            tagName={'div'}
                            tooltip={item.team.name}
                            icon={[
                                {
                                    name: 'users',
                                    stroke: 'none',
                                    fill: item.team.color
                                }
                            ]}
                        />
                    )}

                    <span className={cn(styles.type, selected == item.uuid && styles.selected)}>
                        {item.metadata?.tcIn ? (
                            <>
                                {item.metadata.tcIn}
                                {item.metadata.tcOut && `> ${item.metadata.tcOut}`}
                            </>
                        ) : (
                            <>
                                <span>{t(noteTypeToI18n(item.type))}</span>
                                {item.metadata &&
                                    typeof item.metadata.page === 'number' &&
                                    activeAsset?.metadata?.pagesSigned && (
                                        <span>
                                            {' '}
                                            - {t('player.Page')} {item.metadata?.page + 1}
                                        </span>
                                    )}
                            </>
                        )}
                    </span>
                </div>
                <div className={styles.headerRight}>
                    {!!item.task && !castFileUuid && (
                        <>
                            <TaskStatusSelector
                                status={item.task.status}
                                setTaskStatus={updateStatus}
                                withChevron={true}
                            />
                            <Chips className={styles.link} background={colorTextFaded}>
                                <Link
                                    href={projectTasksLink(item.task.project.uuid, item.task.uuid)}
                                >
                                    #{item.task.number}
                                </Link>
                            </Chips>
                        </>
                    )}

                    {item.comments[0].draft && (
                        <Chips className={styles.draft}>{t('player.Draft')}</Chips>
                    )}
                </div>
            </header>
            <NoteComment
                comment={comments[0]}
                noteUuid={item.uuid}
                editing={editing}
                setEditing={setEditing}
                isFirstNoteComment={true}
            >
                {item.task?.estimatedEndDate && (
                    <Chips>{remainingDaysToDateShortString(item.task.estimatedEndDate)}</Chips>
                )}
                {item.task?.assignedUsers && item.task.assignedUsers.length > 0 && (
                    <div className={styles.users}>
                        {item.task.assignedUsers.map((user) => (
                            <Chips key={user.uuid}>
                                <Icon icon="at" />
                                {getDisplayName(user)}
                            </Chips>
                        ))}
                    </div>
                )}
            </NoteComment>

            {extraComments.length > 1 && (
                <Button className={styles.more} onClick={() => setDisplayMore(!displayMore)}>
                    {t(displayMore ? 'common.Hide' : 'player.Display {{n}} other comments', {
                        n: extraComments.length - 1
                    })}
                </Button>
            )}
            {extraComments.length > 0 && (
                <div className={styles.comments}>
                    <ul>
                        {!displayMore && lastReply && (
                            <li>
                                <NoteComment
                                    comment={lastReply}
                                    noteUuid={item.uuid}
                                    editing={editing}
                                    setEditing={setEditing}
                                />
                            </li>
                        )}
                        {displayMore &&
                            extraComments.map((comment) => (
                                <li key={comment.uuid}>
                                    <NoteComment
                                        comment={comment}
                                        noteUuid={item.uuid}
                                        editing={editing}
                                        setEditing={setEditing}
                                    />
                                </li>
                            ))}
                    </ul>
                </div>
            )}
            {replying !== item.uuid ? (
                <Button
                    className={styles.reply}
                    onClick={() => setReplying(item.uuid)}
                    icon={[
                        {
                            name: 'arrow_reply',
                            stroke: 'white',
                            fill: 'none'
                        }
                    ]}
                >
                    {t('common.Reply')}
                </Button>
            ) : (
                <FormComment noteUuid={item.uuid} cancel={() => setReplying(null)} />
            )}
        </div>
    );
}
