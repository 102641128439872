import { z } from 'zod';
import { EventIntervalUnit } from '../db/enums';
import { sanitizedString } from '@he-novation/utils/zod.utils';
import { workspaceParamsSchema } from './workspace.payload';

export const eventListQuerySchema = z.object({
    start: z.coerce.date().optional(),
    end: z.coerce.date().optional(),
    projectUuid: z.string().uuid().optional()
});

export type EventListQuery = z.infer<typeof eventListQuerySchema>;

export const eventSpecificParamsSchema = z
    .object({
        eventUuid: z.string().uuid()
    })
    .merge(workspaceParamsSchema);

export type EventSpecificParams = z.infer<typeof eventSpecificParamsSchema>;

export const eventCreateBody = z.object({
    title: sanitizedString({ min: 2 }),
    description: sanitizedString({ optional: true }),
    color: z.string().optional().nullable(),
    startDate: z.coerce.date(),
    endDate: z.coerce.date().optional().nullable(),
    duration: z.number(),
    intervalUnit: z.nativeEnum(EventIntervalUnit).default(EventIntervalUnit.DAY),
    intervalValue: z.coerce.number().default(0),
    projectUuid: z.string().uuid().optional().nullable(),
    teamUuids: z.string().uuid().array().optional(),
    itemUuids: z.string().uuid().array().optional(),
    userUuids: z.string().uuid().array().optional(),
    createChatRoom: z.coerce.boolean(),
    notifyUsers: z.coerce.boolean(),
    clientEventLabelUids: z.array(z.string()).optional(),
    monday: z.boolean(),
    tuesday: z.boolean(),
    wednesday: z.boolean(),
    thursday: z.boolean(),
    friday: z.boolean(),
    saturday: z.boolean(),
    sunday: z.boolean()
});

export type EventCreateBody = z.infer<typeof eventCreateBody>;

export const eventCreateResult = z.object({
    uuid: z.string().uuid()
});

export const eventUpdateBody = z.object({
    title: sanitizedString({ min: 2, optional: true }),
    description: sanitizedString({ optional: true }),
    color: z.string().optional().nullable(),
    startDate: z.coerce.date().optional().nullable(),
    endDate: z.coerce.date().optional().nullable(),
    duration: z.number().optional(),
    intervalUnit: z.nativeEnum(EventIntervalUnit).default(EventIntervalUnit.DAY),
    intervalValue: z.coerce.number().default(0),
    projectUuid: z.string().uuid().optional().nullable(),
    teamUuids: z.string().uuid().array().optional(),
    itemUuids: z.string().uuid().array().optional(),
    userUuids: z.string().uuid().array().optional(),
    createChatRoom: z.boolean().optional(),
    notifyUsers: z.boolean().optional(),
    clientEventLabelUids: z.string().array().optional(),
    monday: z.boolean().optional(),
    tuesday: z.boolean().optional(),
    wednesday: z.boolean().optional(),
    thursday: z.boolean().optional(),
    friday: z.boolean().optional(),
    saturday: z.boolean().optional(),
    sunday: z.boolean().optional()
});

export type EventUpdateBody = z.infer<typeof eventUpdateBody>;

export const eventReminderSpecific = eventSpecificParamsSchema.extend({
    reminderUuid: z.string().uuid()
});

export type EventReminderSpecific = z.infer<typeof eventReminderSpecific>;

export const eventReminderCreateBody = z.object({
    datetime: z.date()
});

export type EventReminderCreateBody = z.infer<typeof eventReminderCreateBody>;

export const eventDeletedDayCreateBody = z.object({
    date: z.date()
});

export type EventDeletedDayCreateBody = z.infer<typeof eventDeletedDayCreateBody>;
