import { z } from 'zod';
import { Group } from '../paths/herawApiPathGroups';
import { ApiMethod } from '../types/api.types';
import { folderSizeSchema, fullFolderSchema, trashInfosSchema } from '../types/folder.types';
import {
    oaFolderCreateBody,
    oaFolderInviteBody,
    oaFolderSchema,
    oaFolderSpecificOptionalSchema,
    oaFolderSpecificSchema,
    oaFolderTeamsBody,
    oaOptionalFolderUuidSchema,
    oaWorkspaceNameAndOptionalFolderUuidSchema
} from '../types/open-api/folder.open-api.types';
import {
    bulkDeleteFoldersBody,
    folderBasicParam,
    folderCopyTreeBody,
    folderCreateBody,
    folderInviteBody,
    folderModifyAccessBody,
    folderSizeQuery,
    folderTeamsBody,
    folderUpdateBody,
    optionalPendingSchema,
    folderParamsSchema,
    optionalFolderParamsSchema,
    workspaceNameFolderUuidAndUserUuidSchema
} from '../types/payloads/folder.payload';
import { workspaceParamsSchema } from '../types/payloads/workspace.payload';
import { folderContentFindResponse } from '../types/responses/folder.responses';

export const API_FOLDER = {
    GET: {
        method: ApiMethod.GET,
        path: [
            '/workspaces/:workspaceName/folder/:folderUuid?',
            '/workspaces/:workspaceName/folder'
        ],
        handlerMethod: 'findFolder' as 'findFolder',
        zod: {
            params: optionalFolderParamsSchema
        },
        responses: {
            200: {
                description: 'The requested folder',
                schema: fullFolderSchema
            }
        }
    },
    GET_OA: {
        method: ApiMethod.GET,
        path: [
            '/public/v1/workspaces/:workspaceName/folder',
            '/public/v1/workspaces/:workspaceName/folder/:folderUuid?'
        ],
        handlerMethod: 'findFolder' as 'findFolder',
        description: 'Get the root folder',
        zod: {
            params: oaWorkspaceNameAndOptionalFolderUuidSchema
        },
        responses: {
            200: {
                description: "API User's root folder",
                schema: oaFolderSchema
            }
        },
        publicApi: true,
        group: Group.Folders
    },
    GET_OA_DEPRECATED: {
        method: ApiMethod.GET,
        path: ['/public/v1/folder', '/public/v1/folder/:folderUuid'],
        handlerMethod: 'findFolder' as 'findFolder',
        description: 'Get the root folder',
        zod: {
            params: oaOptionalFolderUuidSchema
        },
        responses: {
            200: {
                description: "API User's root folder",
                schema: oaFolderSchema
            }
        },
        publicApi: 'deprecated' as 'deprecated',
        group: Group.Folders
    },
    PATCH: {
        method: ApiMethod.PATCH,
        path: '/workspaces/:workspaceName/folder/:folderUuid',
        handlerMethod: 'updateFolder' as 'updateFolder',
        zod: {
            params: folderParamsSchema,
            body: folderUpdateBody
        }
    },
    POST: {
        method: ApiMethod.POST,
        path: '/public/v1/workspaces/:workspaceName/folder',
        handlerMethod: 'createFolder' as 'createFolder',
        zod: {
            params: workspaceParamsSchema,
            body: folderCreateBody
        },
        responses: {
            200: {
                description: 'The created folder',
                schema: fullFolderSchema
            }
        }
    },

    POST_OA: {
        method: ApiMethod.POST,
        path: '/public/v1/workspaces/:workspaceName/folder',
        handlerMethod: 'createFolder' as 'createFolder',
        zod: {
            params: workspaceParamsSchema,
            body: oaFolderCreateBody
        },
        description: 'Create a folder',
        responses: {
            201: {
                description: 'Folder created',
                schema: oaFolderSchema
            }
        },
        publicApi: true,
        group: Group.Folders
    },

    POST_OA_DEPRECATED: {
        method: ApiMethod.POST,
        path: '/public/v1/folder',
        handlerMethod: 'createFolder' as 'createFolder',
        zod: {
            body: oaFolderCreateBody
        },
        description: 'Create a folder',
        responses: {
            201: {
                description: 'Folder created',
                schema: oaFolderSchema
            }
        },
        publicApi: 'deprecated' as 'deprecated',
        group: Group.Folders
    },

    DELETE_OA: {
        method: ApiMethod.DELETE,
        path: '/public/v1/workspaces/:workspaceName/folder/:folderUuid',
        handlerMethod: 'deleteFolder' as 'deleteFolder',
        zod: {
            params: oaWorkspaceNameAndOptionalFolderUuidSchema
        },
        responses: {
            204: {
                description: 'Folder deleted'
            }
        },
        description: 'Delete folder',
        publicApi: true,
        group: Group.Folders
    },

    DELETE_OA_DEPRECATED: {
        method: ApiMethod.DELETE,
        path: '/public/v1/folder/:folderUuid',
        handlerMethod: 'deleteFolder' as 'deleteFolder',
        zod: {
            params: oaFolderSpecificSchema
        },
        responses: {
            204: {
                description: 'Folder deleted'
            }
        },
        description: 'Delete folder',
        publicApi: 'deprecated' as 'deprecated',
        group: Group.Folders
    },

    MOVE: {
        method: ApiMethod.PUT,
        path: '/workspaces/:workspaceName/folder/:sourceFolderUuid/move/:targetFolderUuid',
        handlerMethod: 'moveFolder' as 'moveFolder',
        zod: {
            params: z.object({
                workspaceName: z.string(),
                sourceFolderUuid: z.string().uuid(),
                targetFolderUuid: z.string().uuid()
            })
        }
    },
    BULK_DELETE: {
        method: ApiMethod.DELETE,
        path: '/workspaces/:workspaceName/folders',
        handlerMethod: 'bulkDeleteFolders' as 'bulkDeleteFolders',
        zod: {
            params: workspaceParamsSchema,
            body: bulkDeleteFoldersBody
        }
    },
    COPY_TREE: {
        method: ApiMethod.POST,
        path: '/workspaces/:workspaceName/folders/copy-tree',
        handlerMethod: 'copyFolderTree' as 'copyFolderTree',
        zod: {
            params: workspaceParamsSchema,
            body: folderCopyTreeBody
        }
    },
    RESTORE: {
        method: ApiMethod.POST,
        path: '/workspaces/:workspaceName/folder-content/:folderUuid/restore',
        handlerMethod: 'restoreFolder' as 'restoreFolder',
        zod: {
            params: folderParamsSchema
        }
    },
    RECURSIVE_PATCH: {
        method: ApiMethod.PATCH,
        path: '/workspaces/:workspaceName/folders/:folderUuid/recursive',
        handlerMethod: 'recursiveUpdate' as 'recursiveUpdate',
        zod: {
            params: folderParamsSchema,
            body: z.object({
                folder: z.object({
                    triggers: z.array(z.string()).optional(),
                    metadata: z.any(),
                    admittance: z.boolean().optional(),
                    public: z.boolean().optional(),
                    watermark: z.boolean().optional(),
                    export_mode: z.string().optional(),
                    tags: z.array(z.string()).optional(),
                    labels: z.array(z.string()).optional(),
                    encrypted: z.boolean().optional(),
                    default_presets: z.boolean().optional(),
                    public_download: z.boolean().optional(),
                    public_password: z.string().optional(),
                    metadata_user: z.any()
                })
            })
        }
    },
    PATH_GET: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/folder/:folderUuid/path',
        handlerMethod: 'getPath' as 'getPath',
        zod: {
            params: folderParamsSchema
        }
    },
    SHARED_GET: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/shared-folder',
        handlerMethod: 'getShared' as 'getShared',
        zod: {
            params: workspaceParamsSchema
        }
    },
    SIZE_GET: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/folder/:folderUuid/size',
        handlerMethod: 'getFolderSize' as 'getFolderSize',
        zod: {
            params: folderParamsSchema,
            query: folderSizeQuery
        },
        responses: {
            200: {
                description: 'Folder size',
                schema: folderSizeSchema
            }
        }
    }
};

export const API_FOLDER_ACCESS_REQUEST = {
    POST: {
        method: ApiMethod.POST,
        path: '/workspaces/:workspaceName/folders/:folderUuid/access-request',
        handlerMethod: 'requestFolderAccess' as 'requestFolderAccess',
        passResAndNextToHandler: true as true,
        isPublic: true,
        zod: {
            params: folderParamsSchema,
            body: z.object({
                signupData: z
                    .object({
                        email: z.string().email(),
                        firstname: z.string(),
                        lastname: z.string(),
                        firm: z.string(),
                        phone: z.string(),
                        role: z.string(),
                        locale: z.string(),
                        country: z.string(),
                        accept: z.boolean(),
                        address: z.string()
                    })
                    .nullable()
                    .optional()
            })
        }
    }
};

export const API_FOLDER_CONTENT = {
    GET: {
        method: ApiMethod.GET,
        path: [
            '/workspaces/:workspaceName/folder-content/:folderUuid?',
            '/workspaces/:workspaceName/folder-content'
        ],
        handlerMethod: 'getContent' as 'getContent',
        zod: {
            params: optionalFolderParamsSchema,
            query: folderBasicParam
        }
    },
    GET_OA: {
        method: ApiMethod.GET,
        path: '/public/v1/workspaces/:workspaceName/folder/:folderUuid/content',
        handlerMethod: 'getContent' as 'getContent',
        description: 'Get folder content',
        zod: {
            params: folderParamsSchema,
            query: folderBasicParam
        },
        responses: {
            200: {
                description: "Requested folder's content",
                schema: folderContentFindResponse
            }
        },
        publicApi: true,
        group: Group.Folders
    },
    GET_OA_DEPRECATED: {
        method: ApiMethod.GET,
        path: '/public/v1/folder-content/:folderUuid',
        handlerMethod: 'getContent' as 'getContent',
        description: 'Get folder content',
        zod: {
            params: oaFolderSpecificOptionalSchema,
            query: folderBasicParam
        },
        responses: {
            200: {
                description: "Requested folder's content",
                schema: folderContentFindResponse
            }
        },
        publicApi: 'deprecated' as 'deprecated',
        group: Group.Folders
    }
};

export const API_FOLDER_PLUGINS = {
    LIST: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/folder/:folderUuid/plugins',
        handlerMethod: 'folderPluginsSelect' as 'folderPluginsSelect',
        zod: {
            params: folderParamsSchema
        },
        responses: {
            200: {
                description: 'Folder plugins',
                schema: z.array(z.string())
            }
        }
    }
};

export const API_FOLDER_TEAMS = {
    LIST: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/folder/:folderUuid/teams',
        handlerMethod: 'listTeams' as 'listTeams',
        zod: {
            params: folderParamsSchema
        }
    },
    PUT: {
        method: ApiMethod.PUT,
        path: '/workspaces/:workspaceName/folder/:folderUuid/teams',
        handlerMethod: 'setTeams' as 'setTeams',
        zod: {
            params: folderParamsSchema,
            body: folderTeamsBody
        }
    },
    PUT_OA: {
        method: ApiMethod.PUT,
        path: '/public/v1/workspaces/:workspaceNamefolder/:folderUuid/teams',
        handlerMethod: 'setTeams' as 'setTeams',
        zod: {
            params: folderParamsSchema,
            body: oaFolderTeamsBody
        },
        responses: {
            204: {
                description: 'Folder teams have been updated'
            }
        },
        description: 'Changes project teams with access to folder',
        publicApi: true,
        group: Group.Folders
    },
    PUT_OA_DEPRECATED: {
        method: ApiMethod.PUT,
        path: '/public/v1/folder/:folderUuid/teams',
        handlerMethod: 'setTeams' as 'setTeams',
        zod: {
            params: oaFolderSpecificSchema,
            body: oaFolderTeamsBody
        },
        responses: {
            204: {
                description: 'Folder teams have been updated'
            }
        },
        description: 'Changes project teams with access to folder',
        publicApi: 'deprecated' as 'deprecated',
        group: Group.Folders
    }
};

export const API_FOLDER_USER = {
    LIST: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/folder/:folderUuid/users',
        handlerMethod: 'getMembers' as 'getMembers',
        zod: {
            params: folderParamsSchema,
            query: optionalPendingSchema
        }
    },
    POST: {
        method: ApiMethod.POST,
        path: '/workspaces/:workspaceName/folder/:folderUuid/users',
        handlerMethod: 'grantAccess' as 'grantAccess',
        zod: {
            params: folderParamsSchema,
            body: folderInviteBody
        }
    },
    DELETE: {
        method: ApiMethod.DELETE,
        path: '/workspaces/:workspaceName/folder/:folderUuid/users/:userUuid',
        handlerMethod: 'revokeAccess' as 'revokeAccess',
        zod: {
            params: workspaceNameFolderUuidAndUserUuidSchema
        }
    },
    PATCH: {
        method: ApiMethod.PATCH,
        path: '/workspaces/:workspaceName/folder/:folderUuid/users/:userUuid',
        handlerMethod: 'modifyFolderAccess' as 'modifyFolderAccess',
        zod: {
            params: workspaceNameFolderUuidAndUserUuidSchema,
            body: folderModifyAccessBody
        }
    },
    POST_OA: {
        method: ApiMethod.POST,
        path: '/public/v1/workspaces/:workspaceName/folder/:folderUuid/user',
        handlerMethod: 'grantAccess' as 'grantAccess',
        zod: {
            params: folderParamsSchema,
            body: oaFolderInviteBody
        },
        responses: {
            204: {
                description: 'Access granted'
            }
        },
        description: 'Invite users to a folder',
        publicApi: true,
        group: Group.Folders
    },
    POST_OA_DEPRECATED: {
        method: ApiMethod.POST,
        path: '/public/v1/folder/:folderUuid/invite',
        handlerMethod: 'grantAccess' as 'grantAccess',
        zod: {
            params: oaFolderSpecificSchema,
            body: oaFolderInviteBody
        },
        responses: {
            204: {
                description: 'Access granted'
            }
        },
        description: 'Invite users to a folder',
        publicApi: 'deprecated' as 'deprecated',
        group: Group.Folders
    }
};

export const API_TRASH = {
    GET: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/trash',
        handlerMethod: 'getTrash' as 'getTrash',
        zod: {
            params: workspaceParamsSchema
        },
        responses: {
            200: {
                description: 'Trash infos',
                schema: trashInfosSchema
            }
        }
    }
};
