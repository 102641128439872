import { API_SUBTITLE } from '../apis/subtitle-api';
import { pathWithParams } from './herawApiPaths';

export const doubleDigit = (number: number) => (number < 10 ? '0' + number : number);

export const PUBLIC_FILE = '/public-file';

export const CAST_FOLDER = '/cast/:castUid/?castFolderUuid';
export const CAST_FILE = '/cast/:castUid/file/:castFileUuid/:version';
export const CAST = '/casts/:castUid/?castFolderUuid';
export const CASTS = '/casts';
export const CHAT_ROOM = '/chat/room/:roomUuid';
export const CONTACTS = '/directory/contacts';
export const CONTACT_GROUP = '/directory/contact-group/:uuid';
export const DASHBOARD = '/dashboard';
export const DIRECTORY = '/directory/?route/?subroute';
export const DIRECTORY_COMPANIES = '/directory/companies';
export const DIRECTORY_CONTACTS = '/directory/contacts';
export const DIRECTORY_ITEMS = '/directory/items';
export const DIRECTORY_ITEM_KIT = '/directory/item-kit/:uuid';
export const FILE = '/player/:fileUuid/v/:fileVersion/?extra';
export const FILE_COMPARE = '/player/:fileUuid/v/:fileVersion/compare/:comparisonVersion';
export const HOME = '';
export const FOLDER = '/folders/?folderUuid';
export const FOLDER_PUBLIC = '/public/:folderUuid/:userUuid';
export const PLAYER = '/player/:fileUuid/v/:fileVersion';
export const PDF_FILE_SUMMARY = '/summary/file/:fileUuid/v/:fileVersion';
export const PDF_FOLDER_SUMMARY = '/summary/folder/:folderUuid';
export const AVID_FILE_SUMMARY = '/summary_avid/file/:fileUuid/v/:fileVersion';
export const PLANNING = '/planning/?route';
export const PROJECTS = '/projects';
export const PROJECT = '/project/:uuid/?route';
export const PROJECT_CASTS = '/project/:uuid/casts';
export const PROJECT_CAST = '/project/:uuid/cast/:castUid/?castFolderUuid';
export const PROJECT_FOLDERS = '/project/:uuid/folders/?folderUuid';
export const PROJECT_STATS = '/project/:uuid/stats';
export const PROJECT_ACTIVITY = '/project/:uuid/activity';
export const PROJECT_PLANNING = '/project/:uuid/planning';
export const PROJECT_SEARCH = '/project/:projectUuid/search/?query';
export const PROJECT_TASKS = '/project/:uuid/tasks';
export const PROJECT_ROUTES = [
    PROJECT,
    PROJECT_CASTS,
    PROJECT_CAST,
    PROJECT_FOLDERS,
    PROJECT_STATS,
    PROJECT_ACTIVITY,
    PROJECT_PLANNING,
    PROJECT_SEARCH,
    PROJECT_TASKS
];
export const RIGHTS = '/:locale/rights';
export const SETTINGS = '/settings/?mode/?route';
export const SETTINGS_ADMIN = '/settings/admin/?route';
export const SETTINGS_ADMIN_GENERAL = '/settings/admin/general';
export const SETTINGS_ADMIN_CLIENTS = '/settings/admin/clients';
export const SETTINGS_ADMIN_TRANSCODERS = '/settings/admin/transcoders';
export const SETTINGS_ADMIN_WORDING = '/settings/admin/wording';
export const SETTINGS_CLIENTS = '/settings/client/?route';
export const SETTINGS_CLIENTS_DASHBOARD = '/settings/client/dashboard';
export const SETTINGS_CLIENT_GUESTS = '/settings/client/guests';
export const SETTINGS_CLIENT_INTERFACE = '/settings/client/interface';
export const SETTINGS_CLIENT_MISC = '/settings/client/misc';
export const SETTINGS_CLIENT_PLUGINS = '/settings/client/plugins';
export const SETTINGS_CLIENT_SECURITY = '/settings/client/security';
export const SETTINGS_CLIENT_TAGS_AND_LABELS = '/settings/client/tags-labels';
export const SETTINGS_CLIENT_USERS = '/settings/client/users';
export const SETTINGS_USER = '/settings/user/?route';
export const SETTINGS_USER_INTERFACE = '/settings/user/interface';
export const SETTINGS_USER_LICENCES = '/settings/user/licences';
export const SETTINGS_USER_NOTIFICATIONS = '/settings/user/notifications';
export const SETTINGS_USER_PROFILE = '/settings/user/profile';
export const SETTINGS_USER_PLUGINS = '/settings/user/plugins';
export const SETTINGS_USER_RIGHTS = '/settings/user/rights';
export const SETTINGS_USER_TAGS_AND_LABELS = '/settings/user/tags-labels';
export const TASKS = '/tasks';
export const OFFICE = 'https://view.officeapps.live.com/op/embed.aspx?';
export const OFFICE_FILE =
    'src=:baseUrl/proxy/office/:accessToken/:requestToken/:fileUuid/:fileVersion/:fileName';
export const SEARCH = '/search/?query';
export const TRASH = '/trash/?folderUuid';

export const heraw = () => 'https://heraw.com';

export const homeLink = () => '/';

export const adminSettingsLink = () => SETTINGS_ADMIN;

export const castsLink = () => CASTS;

export const castLink = (castUid: string, castFolderUuid?: string) =>
    uriWithParams(CAST, { castUid, castFolderUuid });

export const chatRoom = (roomUuid: string) => uriWithParams(CHAT_ROOM, { roomUuid });

export const clientSettingsLink = (route?: string) => uriWithParams(SETTINGS_CLIENTS, { route });

export const contactsLink = () => DIRECTORY_CONTACTS;

export const contactGroupLink = (uuid: string) => uriWithParams(CONTACT_GROUP, { uuid });

export const dashboardLink = () => DASHBOARD;

export const demoLink = () => '/demo';

export const directoryLink = () => '/directory';

export const directoryCompaniesLink = () => '/directory/companies';

export const directoryContactsLink = () => '/directory/contacts';

export const directoryItemsLink = () => '/directory/items';

export const directoryItemKitLink = (uuid: string) => uriWithParams(DIRECTORY_ITEM_KIT, { uuid });

export const folderLink = (folderUuid?: string) => uriWithParams(FOLDER, { folderUuid });

export const folderPublicLink = (folderUuid: string, userUuid: string) =>
    uriWithParams(FOLDER_PUBLIC, { folderUuid, userUuid });

export const castFileLink = (castUid: string, castFileUuid: string, version: string | number) =>
    uriWithParams(CAST_FILE, { castUid, castFileUuid, version });

export const castFolderLink = (castUid: string, castFolderUuid?: string) =>
    uriWithParams(CAST_FOLDER, { castUid, castFolderUuid });

export const indexLink = () => '/';

export const planningLink = (date?: Date, eventUuid?: string) =>
    uriWithParams(PLANNING, {}, dateToStartQueryParams(date, eventUuid));
export const planningItemsLink = (date?: Date) =>
    uriWithParams(PLANNING, { route: 'items' }, dateToStartQueryParams(date));
export const planningGlobalLink = (date?: Date) =>
    uriWithParams(PLANNING, { route: 'global' }, dateToStartQueryParams(date));

export const playerLink = (
    fileUuid: string,
    fileVersion: number | string,
    {
        noteUuid,
        sidePanel,
        selected_note
    }: { noteUuid?: string; sidePanel?: string; selected_note?: string } = {}
) =>
    uriWithParams(
        FILE,
        { fileUuid, fileVersion },
        {
            filters:
                noteUuid || selected_note
                    ? encodeURIComponent(JSON.stringify({ noteUuid, selected_note }))
                    : undefined,
            sidePanel
        }
    );

export const playerPublicLink = (
    folderUuid: string,
    userUuid: string,
    fileUuid: string,
    fileVersion: number
) =>
    uriWithParams(
        FOLDER_PUBLIC,
        { folderUuid, userUuid },
        { modal: PUBLIC_FILE, uuid: fileUuid, v: fileVersion }
    );

export const playerCompareLink = (
    comparisonVersion: number,
    fileUuid: string,
    fileVersion: number
) => uriWithParams(FILE_COMPARE, { fileUuid, fileVersion, comparisonVersion });

export const pdfFileSummaryLink = (fileUuid: string, fileVersion: number) =>
    uriWithParams(PDF_FILE_SUMMARY, { fileUuid, fileVersion });

export const avidFileSummaryLink = (fileUuid: string, fileVersion: number) =>
    uriWithParams(AVID_FILE_SUMMARY, { fileUuid, fileVersion });

export const pdfFolderSummaryLink = (folderUuid: string) =>
    uriWithParams(PDF_FOLDER_SUMMARY, { folderUuid });

export const officeLink = (assetSignedUrl: string) =>
    OFFICE + 'src=' + encodeURIComponent(assetSignedUrl);

export const projectsLink = () => PROJECTS;

export const projectStatsLink = (uuid: string) => uriWithParams(PROJECT_STATS, { uuid });

export const projectCastLink = (uuid: string, castUid: string, castFolderUuid?: string) =>
    uriWithParams(PROJECT_CAST, { uuid, castUid, castFolderUuid });

export const projectActivityLink = (uuid: string) => uriWithParams(PROJECT_ACTIVITY, { uuid });

export const projectPlanningLink = (uuid: string, date?: Date) =>
    uriWithParams(PROJECT_PLANNING, { uuid }, dateToStartQueryParams(date));

export const projectCastsLink = (uuid: string) => uriWithParams(PROJECT_CASTS, { uuid });

export const projectFoldersLink = (uuid: string, folderUuid?: string) =>
    uriWithParams(PROJECT_FOLDERS, { uuid, folderUuid });

export function projectSearchLink(projectUuid: string, q?: string) {
    return uriWithParams(PROJECT_SEARCH, { projectUuid }, { q });
}
export const projectTasksLink = (uuid: string, taskUuid?: string) =>
    uriWithParams(PROJECT_TASKS, { uuid }, { task: taskUuid });

export const rightsLink = (locale: string) =>
    process.env.MARKETING_URL + uriWithParams(RIGHTS, { locale });

export function searchLink(search?: string, folder?: { uuid: string; name: string }) {
    return uriWithParams(
        SEARCH,
        {},
        { q: search, folderUuid: folder?.uuid, folderName: folder?.name }
    );
}
export const userSettingsLink = (route?: string) => uriWithParams(SETTINGS_USER, { route });

export const sharedRootLink = () => '/shared';

export const subtitlesFileLink = (
    workspaceName: string,
    subtitleUuid: string,
    format: string,
    frameRate: number | string
) =>
    process.env.API_URL +
    pathWithParams(
        API_SUBTITLE.GET.path,
        { workspaceName, subtitleUuid },
        { format, frameRate: encodeURIComponent(frameRate) }
    );

export const tasksLink = () => TASKS;

export const trashLink = (folderUuid?: string) => uriWithParams(TRASH, { folderUuid });

const uriWithParams = (
    uri: string,
    params: Record<string, string | number | undefined>,
    queryParams?: Record<string, string | number | undefined>
) =>
    uri
        .replace(/\/\?([a-zA-Z]+)/g, (full, g1) => (params[g1] ? '/' + params[g1] : '') as string)
        .replace(/[:?]([a-zA-Z]+)/g, (full, g1) => params[g1]?.toString() as string) +
    (queryParams && Object.keys(queryParams).length
        ? '?' +
          Object.keys(queryParams)
              .map((key) =>
                  typeof queryParams[key] !== 'undefined' ? `${key}=${queryParams[key]}` : ''
              )
              .join('&')
        : ''
    ).replace(/[&?]$/, '');

const dateToStartQueryParams = (date?: Date, event?: string) => {
    const queryParams: any = {};
    if (date) {
        queryParams.start = `${date.getFullYear()}-${doubleDigit(
            date.getMonth() + 1
        )}-${doubleDigit(date.getDate())}`;
    }
    if (event) queryParams.event = event;
    return queryParams;
};
