import { MAX_TREE_DEPTH } from '@he-novation/config/constants/uploads.constants';
import { FolderStateFile } from '@he-novation/config/types/folder.types';
import type {
    FileWithTypeAndThumbnail,
    Folder
} from '@he-novation/design-system/components/form/DropUpload/DropUpload';
import { createFolder, fetchFolderContent } from '@he-novation/front-shared/async/folder.async';
import { FrontFolderContent } from '@he-novation/front-shared/types/folder.front-types';
import { v4 as uuid } from 'uuid';

import { uploadFile } from '$helpers/Uploader';
import { Translator } from '$hooks/useTranslate';
import { UserInfos } from '$redux/user/userSelectors';

type FileToUpload = FileWithTypeAndThumbnail;

type FolderToUpload = Omit<Folder, 'children'> & {
    uuid?: string;
    name?: string;
    path?: string;
    children: (FileToUpload | FolderToUpload)[];
    content?: FrontFolderContent;
};

export type UploadInfo = {
    folder: { uuid: string; name: string; path: string };
    fileUuid?: string;
    file: FileWithTypeAndThumbnail;
    parent?: FolderStateFile;
};

const getTreeDepth = (folder, total = 0) => {
    const depths: number[] = [];
    total++;
    if (folder.children) {
        folder.children.forEach((child) => {
            if (child.entryType === 'folder') {
                depths.push(getTreeDepth(child, total), total);
            }
        });
    }
    return Math.max(total, ...depths);
};

const recursivelyCreateFolders = async (
    userUuid: string,
    workspaceName: string,
    parentFolderUuid: string,
    currentFolderToUpload: FolderToUpload,
    shared: boolean,
    isNewBranch: boolean,
    copyParentProperties?: boolean
) => {
    const firstLevel = currentFolderToUpload.children.filter(function (child) {
        return child.entryType === 'folder';
    }) as FolderToUpload[];

    if (!isNewBranch) {
        currentFolderToUpload.content = await fetchFolderContent(workspaceName, parentFolderUuid);
    }

    const results = await Promise.all(
        firstLevel.map((folder) => {
            const existingFolder =
                !isNewBranch &&
                currentFolderToUpload.content!.find(
                    (item) => item.type === 'folder' && item.name === folder.name
                );
            if (existingFolder) return { ...existingFolder, exists: true };
            return createFolder(workspaceName, {
                folderUuid: parentFolderUuid,
                name: folder.name!,
                copyParentProperties
            });
        })
    );

    await Promise.all(
        firstLevel.map(function (child, i) {
            child.uuid = results[i]!.uuid;
            child.path = (currentFolderToUpload.path + '/' + child.name).replace('//', '/');
            return recursivelyCreateFolders(
                userUuid,
                workspaceName,
                results[i]!.uuid,
                child,
                shared,
                isNewBranch || !('exists' in results[i]),
                copyParentProperties
            );
        })
    );
};

const recursivelyGetUploadInfos = (
    targetFolder: { uuid: string; name: string },
    contentToUpload: FolderToUpload
) => {
    const uploadInfos: UploadInfo[] = [];

    contentToUpload.children.forEach(function (child) {
        if (child.entryType === 'folder') {
            uploadInfos.push(
                ...recursivelyGetUploadInfos(
                    { uuid: contentToUpload.uuid!, name: contentToUpload.name! },
                    child as FolderToUpload
                )
            );
            return;
        }
        if (child.name!.startsWith('.')) return;
        uploadInfos.push({
            folder: {
                uuid: contentToUpload.uuid!,
                name: contentToUpload.name!,
                path: contentToUpload.path
            },
            file: child as FileWithTypeAndThumbnail,
            parent: contentToUpload?.content?.find(
                (c) => c.type === 'file' && c.name === child.name
            ) as FolderStateFile | undefined
        });
    });
    return uploadInfos;
};

export const uploadTree = async (
    userInfos: UserInfos,
    workspaceName,
    userUuid: string,
    rootFolder: { uuid: string; name: string; shared?: boolean | number },
    contentToUpload: FolderToUpload,
    hasPluginFiletree: boolean,
    preferences: any = {},
    t: Translator
) => {
    await recursivelyCreateFolders(
        userUuid,
        workspaceName,
        rootFolder.uuid,
        contentToUpload,
        !!rootFolder.shared,
        false,
        preferences?.replicate_parent_settings
    );

    const uploadInfos = recursivelyGetUploadInfos(rootFolder, {
        ...contentToUpload,
        uuid: rootFolder.uuid,
        name: rootFolder.name,
        path: `/${rootFolder.name}`
    });

    if (
        contentToUpload.children.find(({ entryType }) => entryType === 'folder') &&
        !hasPluginFiletree
    )
        return alert(t('common.Missing plugin: {{plugin}}', { plugin: 'filetree' }));

    if (getTreeDepth(contentToUpload) > MAX_TREE_DEPTH)
        return alert(
            t('misc.There are too many nested folders, maximum support depth is {{n}}', {
                n: MAX_TREE_DEPTH
            })
        );

    const uploadGroup = uuid();

    return await Promise.all(
        uploadInfos.map(({ file, folder, parent }, i) => {
            uploadFile(workspaceName, userInfos, {
                uploadGroup: {
                    uuid: uploadGroup,
                    name: folder.name
                },
                uploadIndex: i,
                uploadsTotal: uploadInfos.length,
                file: file,
                folder: {
                    uuid: folder.uuid,
                    name: folder.name,
                    shared: rootFolder.shared,
                    path: folder.path
                },
                parentFileUuid: parent?.uuid
            });
        })
    );
};
