import { ApiMethod } from '../types/api.types';
import {
    pluginAuthSchema,
    pluginConfigurationCreationSchema,
    pluginConfigurationSchema,
    pluginExportBody,
    pluginExportParams,
    pluginUuidSchema
} from '../types/payloads/plugin.payload';

export const API_PLUGIN = {
    POST: {
        method: ApiMethod.POST,
        path: '/plugins',
        handlerMethod: 'createPluginConfiguration' as 'createPluginConfiguration',
        zod: {
            body: pluginConfigurationCreationSchema
        }
    },
    PATCH: {
        method: ApiMethod.PATCH,
        path: '/plugins/:pluginUuid',
        handlerMethod: 'updatePluginConfiguration' as 'updatePluginConfiguration',
        zod: {
            params: pluginUuidSchema,
            body: pluginConfigurationSchema
        }
    },
    DELETE: {
        method: ApiMethod.DELETE,
        path: '/plugins/:pluginUuid',
        handlerMethod: 'deletePluginConfiguration' as 'deletePluginConfiguration',
        zod: {
            params: pluginUuidSchema
        }
    }
};

export const API_PLUGIN_AUTH_URL = {
    GET: {
        method: ApiMethod.GET,
        path: '/plugin/auth/:type',
        handlerMethod: 'getPluginAuthUrl' as 'getPluginAuthUrl',
        zod: {
            params: pluginAuthSchema
        }
    }
};

export const API_PLUGIN_EXPORT = {
    POST: {
        method: ApiMethod.POST,
        path: '/plugin/export/:plugin',
        handlerMethod: 'pluginExportFiles' as 'pluginExportFiles',
        zod: {
            params: pluginExportParams,
            body: pluginExportBody
        }
    }
};
