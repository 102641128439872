import { LegacyAuth, UserSession } from './user.types';
import { z } from 'zod';

export type PluginConfigurationType = {
    uuid: string;
    enabled: boolean;
    properties: Record<string, unknown>;
    secrets: Record<string, unknown>;
    name: string;
    type: string;
};
export type PublicPluginConfigurationType = Omit<PluginConfigurationType, 'secrets'>;

export type PluginConfigurationGeneric<T extends boolean | undefined> = T extends true
    ? PluginConfigurationType
    : PublicPluginConfigurationType;

export const pluginExportFile = z.object({
    name: z.string(),
    uuid: z.string(),
    final: z.boolean(),
    version: z.number()
});

export type PluginExportFile = z.infer<typeof pluginExportFile>;

export type PluginExportJob = {
    user: UserSession;
    exportAuth: LegacyAuth;
    configuration: PluginConfigurationType;
    folderUuid: string;
    items: PluginExportFile[];
};
