import { ApiMethod } from '../types/api.types';
import {
    eventCreateBody,
    eventDeletedDayCreateBody,
    eventListQuerySchema,
    eventReminderCreateBody,
    eventReminderSpecific,
    eventSpecificParamsSchema,
    eventUpdateBody
} from '../types/payloads/event.payload';
import { z } from 'zod';
import { herawEventSchema } from '../types/event.types';
import {
    oaWorkspaceParamsSchema,
    workspaceParamsSchema
} from '../types/payloads/workspace.payload';
import { Group } from '../paths/herawApiPathGroups';

export const API_EVENTS = {
    LIST: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/events',
        handlerMethod: 'listEvents' as 'listEvents',
        zod: {
            params: workspaceParamsSchema,
            query: eventListQuerySchema
        },
        responses: {
            200: {
                description: 'List of events in workspace',
                schema: z.array(herawEventSchema)
            }
        }
    },
    GET: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/events/:eventUuid',
        handlerMethod: 'getEvent' as 'getEvent',
        zod: {
            params: eventSpecificParamsSchema
        },
        responses: {
            200: {
                description: 'Event from workspace',
                schema: herawEventSchema
            }
        }
    },
    POST: {
        method: ApiMethod.POST,
        path: '/workspaces/:workspaceName/events',
        handlerMethod: 'createEvent' as 'createEvent',
        zod: {
            params: workspaceParamsSchema,
            body: eventCreateBody
        },
        responses: {
            200: {
                description: 'The created event',
                schema: herawEventSchema
            }
        }
    },
    POST_OA: {
        method: ApiMethod.POST,
        path: '/public/v1/workspaces/:workspaceName/events',
        handlerMethod: 'createEvent' as 'createEvent',
        zod: {
            params: oaWorkspaceParamsSchema,
            body: eventCreateBody
        },
        publicApi: true,
        description: 'Create an event',
        group: Group.Events,
        responses: {
            200: {
                description: 'The created event',
                schema: herawEventSchema
            }
        }
    },
    POST_OA_DEPRECATED: {
        method: ApiMethod.POST,
        path: '/public/v1/event',
        handlerMethod: 'createEvent' as 'createEvent',
        zod: {
            body: eventCreateBody
        },
        publicApi: true,
        description: 'Create an event (deprecated)',
        group: Group.Events,
        responses: {
            200: {
                description: 'The created event',
                schema: herawEventSchema
            }
        }
    },
    PUT: {
        method: ApiMethod.PUT,
        path: '/workspaces/:workspaceName/events/:eventUuid',
        handlerMethod: 'updateEvent' as 'updateEvent',
        zod: {
            params: eventSpecificParamsSchema,
            body: eventUpdateBody
        },
        responses: {
            200: {
                description: 'The updated event',
                schema: herawEventSchema
            }
        }
    },
    DELETE: {
        method: ApiMethod.DELETE,
        path: '/workspaces/:workspaceName/events/:eventUuid',
        handlerMethod: 'deleteEvent' as 'deleteEvent',
        zod: {
            params: eventSpecificParamsSchema
        },
        responses: {
            204: {
                description: 'Event successfully deleted',
                schema: z.unknown()
            }
        }
    }
};

export const API_EVENT_DELETED_DAYS = {
    POST: {
        method: ApiMethod.POST,
        path: '/workspaces/:workspaceName/events/:eventUuid/deleted-days',
        handlerMethod: 'createEventDeletedDay' as 'createEventDeletedDay',
        zod: {
            params: eventSpecificParamsSchema,
            body: eventDeletedDayCreateBody
        },
        response: {
            204: {
                description: 'Event deleted day successfully created',
                schema: z.unknown()
            }
        }
    }
};

export const API_EVENT_REMINDERS = {
    POST: {
        method: ApiMethod.POST,
        path: '/workspaces/:workspaceName/events/:eventUuid/reminders',
        handlerMethod: 'createReminder' as 'createReminder',
        zod: {
            params: eventSpecificParamsSchema,
            body: eventReminderCreateBody
        },
        responses: {
            204: {
                description: 'Event reminder successfully created',
                schema: z.unknown()
            }
        }
    },
    DELETE: {
        method: ApiMethod.DELETE,
        path: '/workspaces/:workspaceName/events/:eventUuid/reminders/:reminderUuid',
        handlerMethod: 'deleteReminder' as 'deleteReminder',
        zod: {
            params: eventReminderSpecific
        },
        responses: {
            204: {
                description: 'Event reminder successfully deleted',
                schema: z.unknown()
            }
        }
    }
};
