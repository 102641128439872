import {
    API_CAST_NOTE,
    API_NOTE,
    API_NOTE_COMMENT,
    API_NOTE_COMMENT_ATTACHMENT
} from '@he-novation/config/apis/note-api';
import type { Comment } from '@he-novation/config/types/note.types';
import { Note } from '@he-novation/config/types/note.types';
import { BaseUploadPayload } from '@he-novation/config/types/payloads/asset.payload';
import {
    CommentCreateBody,
    CommentEditBody,
    NoteCreateBody
} from '@he-novation/config/types/payloads/note.payload';
import { AttachmentUploadUrlResponse } from '@he-novation/config/types/responses/note.responses';
import { dateAsUTCDate, setLatestHour } from '@he-novation/utils/datetime';
import { buildApiCall } from '../../config/apis/api-fetch';

export const deleteNote = (workspaceName: string, noteUuid: string) =>
    buildApiCall(API_NOTE.DELETE)({
        params: { workspaceName, noteUuid }
    });

export const fetchNotes = (
    workspaceName: string,
    fileUuid: string
): Promise<Record<string, Note>> =>
    buildApiCall(API_NOTE.LIST)({
        params: { workspaceName, fileUuid }
    });

export const fetchCastNotes = ({
    castUid,
    castFileUuid,
    fileVersion
}: {
    castUid: string;
    castFileUuid: string;
    fileVersion: number;
}): Promise<Record<string, Note>> =>
    buildApiCall(API_CAST_NOTE.LIST)({
        params: { castUid, castFileUuid, fileVersion }
    });

export const asyncNoteCreate = (
    workspaceName: string,
    body: NoteCreateBody,
    isCastFile?: boolean,
    password?: string
) => {
    if (body.estimatedEndDate)
        body.estimatedEndDate = setLatestHour(dateAsUTCDate(body.estimatedEndDate as Date), true);

    if (isCastFile) {
        return buildApiCall(API_CAST_NOTE.POST)({
            params: { workspaceName },
            query: { p: password },
            body
        });
    }
    return buildApiCall(API_NOTE.POST)({
        params: { workspaceName },
        query: { p: password },
        body
    });
};

export const asyncCommentCreate = (
    workspaceName: string,
    noteUuid: string,
    { content, draft, castFileUuid, notify, postedAs }: CommentCreateBody,
    password?: string
): Promise<Comment> =>
    buildApiCall(API_NOTE_COMMENT.POST)({
        params: { workspaceName, noteUuid },
        query: { p: password },
        body: {
            content,
            draft,
            castFileUuid,
            notify,
            postedAs
        }
    });

export const asyncCommentEdit = (
    workspaceName: string,
    noteUuid: string,
    commentUuid: string,
    { content, draft, tags }: CommentEditBody
): Promise<Omit<Comment, 'note' | 'file'>> =>
    buildApiCall(API_NOTE_COMMENT.PATCH)({
        params: { workspaceName, noteUuid, commentUuid },
        body: {
            content,
            draft,
            tags
        }
    });

export function asyncCommentDelete(workspaceName: string, noteUuid: string, commentUuid: string) {
    return buildApiCall(API_NOTE_COMMENT.DELETE)({
        params: { workspaceName, noteUuid, commentUuid }
    });
}

export const asyncInitUploadAttachments = (
    workspaceName: string,
    {
        noteUuid,
        commentUuid,
        attachments,
        castFileUuid
    }: {
        noteUuid: string;
        commentUuid: string;
        attachments: BaseUploadPayload[];
        castFileUuid?: string;
    }
): Promise<AttachmentUploadUrlResponse> =>
    buildApiCall(API_NOTE_COMMENT_ATTACHMENT.POST)({
        params: { workspaceName, noteUuid, commentUuid },
        body: {
            files: attachments,
            castFileUuid
        }
    });

export const asyncOpenApiNotesFetch = (apiKey: string, fileUuid: string, fileVersion: number) =>
    buildApiCall(API_NOTE.LIST_OA_DEPRECATED)({
        params: { fileUuid, fileVersion },
        options: {
            headers: {
                Authorization: `Bearer ${apiKey}`
            }
        }
    });
