import { ApiMethod } from '../types/api.types';
import {
    userInfoSchema,
    userNotificationSettingsSchema,
    userPreferencesSchema,
    userStorageQuery,
    userUuidSchema,
    userVerifySchema
} from '../types/payloads/user.payload';
import { z } from 'zod';
import { workspaceParamsSchema } from '../types/payloads/workspace.payload';

export const API_USER = {
    GET: {
        method: ApiMethod.GET,
        path: '/account',
        handlerMethod: 'findAccount' as 'findAccount',
        passResAndNextToHandler: true as true,
        allowNoMfa: true
    },
    POST: {
        method: ApiMethod.POST,
        path: '/users',
        handlerMethod: 'signUp' as 'signUp',
        passResAndNextToHandler: true as true,
        isPublic: true,
        zod: {
            body: z.object({
                email: z.string().email(),
                firstname: z.string(),
                lastname: z.string(),
                firm: z.string(),
                phone: z.string(),
                role: z.string(),
                locale: z.string(),
                recaptcha: z.string(),
                plan: z.string().optional(),
                picture: z.string().optional()
            })
        },
        allowNoMfa: true
    },
    PATCH: {
        method: ApiMethod.PATCH,
        path: '/users/:userUuid',
        handlerMethod: 'updateUserInfo' as 'updateUserInfo',
        zod: {
            params: userUuidSchema,
            body: userInfoSchema
        },
        allowNoMfa: true
    },
    DELETE: {
        method: ApiMethod.DELETE,
        path: '/users/:userUuid',
        handlerMethod: 'requestDelete' as 'requestDelete',
        passResAndNextToHandler: true as true,
        zod: {
            params: userUuidSchema
        }
    },
    VERIFY_EMAIL: {
        method: ApiMethod.POST,
        path: '/user/verify',
        handlerMethod: 'verifyEmail' as 'verifyEmail',
        isPublic: true,
        passResAndNextToHandler: true as true,
        zod: {
            body: userVerifySchema
        }
    },
    GET_INFOS: {
        method: ApiMethod.GET,
        path: '/user/infos',
        handlerMethod: 'getAppInfos' as 'getAppInfos',
        allowNoMfa: true
    },
    GET_STORAGE: {
        method: ApiMethod.GET,
        path: '/user/storage',
        handlerMethod: 'findStorage' as 'findStorage',
        zod: {
            query: userStorageQuery
        },
        allowNoMfa: true
    },
    GET_TRANSLATION_CREDITS: {
        method: ApiMethod.GET,
        path: '/user/translation-credits',
        handlerMethod: 'getTranslationCredits' as 'getTranslationCredits'
    }
};

export const API_USER_PREFERENCES = {
    PATCH: {
        method: ApiMethod.PATCH,
        path: '/users/:userUuid/preferences',
        handlerMethod: 'updateUserPreferences' as 'updateUserPreferences',
        passResAndNextToHandler: true as true,
        zod: {
            params: userUuidSchema,
            body: userPreferencesSchema
        },
        allowNoMfa: true
    }
};

export const API_USER_PLUGINS = {
    LIST: {
        method: ApiMethod.GET,
        path: '/user/plugins',
        handlerMethod: 'findPlugins' as 'findPlugins',
        allowNoMfa: true,
        responses: {
            200: {
                schema: z.string().array(),
                description: 'List of plugins'
            }
        }
    }
};

export const API_USER_INTEGRATIONS = {
    LIST: {
        method: ApiMethod.GET,
        path: '/user/plugin-configurations',
        handlerMethod: 'findPluginConfigurations' as 'findPluginConfigurations',
        allowNoMfa: true
    }
};

export const API_USER_NOTIFICATIONS = {
    GET: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/user-notifications',
        handlerMethod: 'findUserNotificationSettings' as 'findUserNotificationSettings',
        zod: {
            params: workspaceParamsSchema
        }
    },
    PUT: {
        method: ApiMethod.PUT,
        path: '/workspaces/:workspaceName/user-notifications',
        handlerMethod: 'putUserNotificationSettings' as 'putUserNotificationSettings',
        zod: {
            params: workspaceParamsSchema,
            body: userNotificationSettingsSchema
        }
    }
};

export const API_USER_LICENSES = {
    LIST: {
        method: ApiMethod.GET,
        path: '/user/licenses',
        handlerMethod: 'fetchAssignedLicences' as 'fetchAssignedLicences',
        allowNoMfa: true
    },
    DELETE: {
        method: ApiMethod.DELETE,
        path: '/user/licenses',
        handlerMethod: 'cancelPrivateLicense' as 'cancelPrivateLicense',
        zod: {
            body: userUuidSchema
        }
    }
};
