import { z } from 'zod';
import { FolderRole } from '../folder.types';
import { workspaceParamsSchema } from './workspace.payload';
import { sanitizedString } from '@he-novation/utils/zod.utils';
export const passwordQuery = z.object({
    p: sanitizedString({ optional: true })
});

export const optionalPendingSchema = z.object({
    pending: z.coerce.boolean().optional()
});

export type PasswordQuery = z.infer<typeof passwordQuery>;

export const folderSpecific = z.object({
    folderUuid: z.string().uuid()
});

export type FolderSpecific = z.infer<typeof folderSpecific>;

export const folderParamsSchema = z.object({
    workspaceName: sanitizedString(),
    folderUuid: z.string().uuid()
});

export type FolderParams = z.infer<typeof folderParamsSchema>;

export const workspaceNameFolderUuidAndUserUuidSchema = z.object({
    workspaceName: sanitizedString(),
    folderUuid: z.string().uuid(),
    userUuid: z.string().uuid()
});

export const optionalFolderParamsSchema = workspaceParamsSchema.extend({
    folderUuid: z.string().uuid().optional()
});

export type OptionalFolderParams = z.infer<typeof optionalFolderParamsSchema>;

export const folderSpecificOptional = z.object({
    folderUuid: z.string().uuid().optional()
});

export type FolderSpecificOptional = z.infer<typeof folderSpecificOptional>;

export const folderMemberSpecificParams = z.object({
    folderUuid: z.string().uuid(),
    userUuid: z.string().uuid()
});

export const folderBasicParam = z.object({
    basic: z.coerce.boolean().optional()
});

export type FolderBasicParam = z.infer<typeof folderBasicParam>;

export type FolderMemberSpecificParams = z.infer<typeof folderMemberSpecificParams>;

export const folderModifyAccessBody = z.object({
    role: z.string().optional(),
    canDownload: z.boolean().optional()
});

export type FolderModifyAccessBody = z.infer<typeof folderModifyAccessBody>;

export const folderSizeQuery = z.object({
    publicSize: z.coerce.boolean().optional(),
    includeVersions: z.coerce.boolean().optional(),
    password: z.string().optional(),
    flat: z.coerce.boolean().optional()
});

export type FolderSizeQuery = z.infer<typeof folderSizeQuery>;

export const folderCreateBody = z.object({
    parentFolderUuid: z.string().uuid(),
    name: z.string(),
    copyParentProperties: z.coerce.boolean().optional(),
    copyParentMembers: z.coerce.boolean().optional(),
    copyParentMembersPending: z.coerce.boolean().optional(),
    labels: z.array(z.string()).optional(),
    teamUuids: z.array(z.string()).optional()
});

export type FolderCreateBody = z.infer<typeof folderCreateBody>;

export const folderUpdateBody = z.object({
    name: z.string().optional(),
    metadata: z
        .object({
            notes: z.string().optional(),
            icon: z.string().optional(),
            cover: z.string().optional(),
            admittance: z
                .union([
                    z.object({
                        role: z.nativeEnum(FolderRole),
                        download: z.boolean(),
                        export: z.boolean()
                    }),
                    z.literal(false)
                ])
                .optional()
        })
        .optional(),
    admittance: z.boolean().optional(),
    public: z.boolean().optional(),
    watermark: z.boolean().optional(),
    public_download: z.boolean().optional(),
    public_password: z.string().optional().nullable(),
    export_mode: z.string().optional(),
    tags: z.array(sanitizedString()).optional(),
    labels: z.array(sanitizedString()).optional(),
    encrypted: z.boolean().optional(),
    default_presets: z.boolean().optional(),
    metadata_user: z.any().optional(),
    triggers: z.string().array().optional()
});
export type FolderUpdateBody = z.infer<typeof folderUpdateBody>;

const notificationValue = z.union([z.enum(['all', 'email', 'desktop']), z.boolean()]);
export const folderNotificationBody = z.object({
    access_grant: notificationValue.optional(),
    comment_add: notificationValue.optional(),
    file_download: notificationValue.optional(),
    file_export: notificationValue.optional(),
    file_new: notificationValue.optional(),
    file_set_final: notificationValue.optional()
});

export type FolderNotificationBody = z.infer<typeof folderNotificationBody>;

export const folderCopyTreeBody = z.object({
    sourceFolderUuid: z.string().uuid(),
    targetFolderUuid: z.string().uuid(),
    name: sanitizedString({ optional: true }),
    renameIfExists: z.boolean().optional()
});
export type FolderCopyTreeBody = z.infer<typeof folderCopyTreeBody>;

export const folderMemberPayload = z.object({
    uuid: z.string().uuid(),
    role: z.nativeEnum(FolderRole),
    canDownload: z.boolean().optional(),
    canExport: z.boolean().optional()
});

export type FolderMemberPayload = z.infer<typeof folderMemberPayload>;

export const folderInviteBody = z.object({
    message: sanitizedString({ optional: true, nullable: true }),
    members: z.array(folderMemberPayload)
});

export type FolderInviteBody = z.infer<typeof folderInviteBody>;

export const folderTeamsBody = z.object({
    teamUuids: z.array(z.string().uuid())
});

export type FolderTeamsBody = z.infer<typeof folderTeamsBody>;

export const bulkDeleteFoldersBody = z.object({
    parentUuid: z.string().uuid(),
    uuids: z.array(z.string().uuid())
});

export type BulkDeleteFoldersBody = z.infer<typeof bulkDeleteFoldersBody>;
