import * as Sentry from '@sentry/react';
import '@he-novation/front-shared/scss/global_vars.css';
import '@he-novation/front-shared/css/global.css';
import './global.css';
import '$redux/store.ts';

import React from 'react';

import { createRoot } from 'react-dom/client';
import App from './views/App/AppContainer';
import { fetchAccount } from '@he-novation/front-shared/async/user.async';
import { asyncAuthRefresh } from '@he-novation/front-shared/async/auth.async';
import { Loader } from '@he-novation/design-system/components/widgets/Loader/Loader';
import { initBrowserSession } from '$helpers/browserSession';

console.info(`HERAW | ${process.env.HERAW_ENV} - ${process.env.HASH}`);
if (process.env.HERAW_ENV !== 'dev') {
    Sentry.init({
        dsn: 'https://ddae8e73c64caf3513a6238a2dfb11da@o4507967361122304.ingest.de.sentry.io/4507967380389968',
        integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
        environment: process.env.HERAW_ENV,
        // Tracing
        tracesSampleRate: 0.05,
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', process.env.APP_URL],
        // Session Replay
        replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        whitelistUrls: [process.env.APP_URL, process.env.API_URL, process.env.ICONS_URL],
        beforeSend(event, hint) {
            try {
                // Check if the hint contains the original exception
                const originalException = hint?.originalException;

                const message = originalException?.message;
                if (message === 'disconnected') {
                    return null;
                }

                const filteredErrors = [
                    'Unable to preload CSS',
                    'Non-Error promise rejection captured with value: View changed'
                ];
                if (filteredErrors.find((e) => message.includes(e))) {
                    return null;
                }
            } catch (err) {
                console.warn('Error in Sentry beforeSend:', err);
            }

            // Allow all other events to be sent
            return event;
        }
    });
}

function keepTokenFresh(expiryMs) {
    const delta = expiryMs - Date.now();
    // Randomize the timeout to avoid all clients refreshing at the same time
    const timeoutValue = delta - 60_000 * (0.5 + Math.random() * 3);
    setTimeout(async () => {
        asyncAuthRefresh().then(({ accessTokenExpiry }) => keepTokenFresh(accessTokenExpiry));
    }, timeoutValue);
}

document.addEventListener('DOMContentLoaded', async () => {
    const root = createRoot(document.getElementById('app'));

    root.render(<Loader />);

    const { host, locale, accessTokenExpiry } = await asyncAuthRefresh();
    if (accessTokenExpiry) {
        keepTokenFresh(accessTokenExpiry);
    }
    const account = await fetchAccount().catch(() => null);
    root.render(<App host={host} locale={account?.locale || locale} account={account} />);
});

initBrowserSession();
