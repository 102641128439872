import { buildApiCall } from '@he-novation/config/apis/api-fetch';
import { paths, pathWithParams } from '@he-novation/config/paths/herawApiPaths';
import type { TaskCreateBody } from '@he-novation/config/types/payloads/task.payload';
import type { TaskCreateOrUpdateResponse } from '@he-novation/config/types/responses/task.responses';
import type { Task, TaskChange, TaskStatus } from '@he-novation/config/types/task.types';
import { dateAsUTCDate, isoStringToUTCDate, setLatestHour } from '@he-novation/utils/datetime';
import { isNull, omitBy } from 'lodash/fp';
import { API_TASK } from '../../config/apis/task-api';
import { apiFetch } from './apiFetch';

export function mapFetchedTask(task: Task): Task {
    if (typeof task.created === 'string') task.created = new Date(task.created);
    if (typeof task.updated === 'string') task.updated = new Date(task.updated);
    if (typeof task.estimatedEndDate === 'string')
        task.estimatedEndDate = new Date(task.estimatedEndDate);
    return task;
}

export const asyncTasksFetch = ({
    workspaceName,
    startDate,
    endDate
}: { workspaceName?: string; startDate?: Date; endDate?: Date } = {}): Promise<Task[]> =>
    buildApiCall(API_TASK.LIST)({
        query: {
            workspaceName,
            startDate: startDate,
            endDate: endDate
        }
    }).then((tasks) => tasks.map(mapFetchedTask));

export const asyncTaskFetch = (
    workspaceName: string,
    projectUuid: string,
    taskUuid: string
): Promise<Task> =>
    buildApiCall(API_TASK.GET)({
        params: {
            workspaceName,
            projectUuid,
            taskUuid
        }
    }).then((t) => ({
        ...t,
        created: new Date(t.created),
        updated: new Date(t.updated || t.created),
        estimatedEndDate: t.estimatedEndDate && isoStringToUTCDate(t.estimatedEndDate)
    }));

/**
 * estimatedEndDate will be converted from localized time to UTC
 * If GMT+1 is 23:59:59, it will be converted to  UTC 23:59:59 or GMT+1 22:59:59
 */
export const asyncTaskPost = async (
    workspaceName: string,
    projectUuid: string,
    {
        assignees,
        attachments,
        teamUuid,
        status,
        description,
        estimatedEndDate
    }: Omit<TaskCreateBody, 'estimatedEndDate'> & {
        estimatedEndDate?: Date | null;
    }
): Promise<TaskCreateOrUpdateResponse> => {
    if (estimatedEndDate) estimatedEndDate = setLatestHour(dateAsUTCDate(estimatedEndDate), true);
    return buildApiCall(API_TASK.POST)({
        params: {
            workspaceName,
            projectUuid
        },
        body: {
            assignees,
            attachments,
            teamUuid,
            status,
            description,
            estimatedEndDate
        }
    }).then((r) => ({
        task: mapFetchedTask(r.task),
        attachments: r.attachments
    }));
};

/**
 * estimatedEndDate will be converted from localized time to UTC
 * If GMT+1 is 23:59:59, it will be converted to  UTC 23:59:59 or GMT+1 22:59:59
 */
export const asyncTaskUpdate = (
    workspaceName: string,
    projectUuid: string,
    {
        taskUuid,
        assignees,
        attachments,
        teamUuid,
        status,
        description,
        estimatedEndDate
    }: {
        taskUuid: string;
        assignees?: string[];
        attachments?: string[];
        teamUuid?: string;
        status?: string;
        description?: string;
        estimatedEndDate?: Date;
    }
): Promise<TaskCreateOrUpdateResponse> => {
    if (estimatedEndDate) estimatedEndDate = setLatestHour(dateAsUTCDate(estimatedEndDate), true);
    return buildApiCall(API_TASK.PATCH)({
        params: {
            workspaceName,
            projectUuid,
            taskUuid
        },
        body: omitBy(isNull, {
            assignees: Array.isArray(assignees) ? assignees.join(',') : assignees,
            attachments,
            teamUuid,
            status,
            description,
            estimatedEndDate
        })
    }).then((r) => ({
        task: mapFetchedTask(r.task),
        attachments: r.attachments
    }));
};

export async function updateMultipleTaskStatusAndOrdering(
    browserSessionUuid: string,
    changes: TaskChange[]
): Promise<{ [taskUuid: string]: { orderingDelta: number; status: TaskStatus } }> {
    return buildApiCall(API_TASK.UPDATE_MULTIPLE_STATUS_ORDERING)({
        body: {
            browserSessionUuid,
            changes
        }
    });
}

export const asyncTaskDelete = (workspaceName: string, projectUuid: string, taskUuid: string) =>
    buildApiCall(API_TASK.DELETE)({
        params: {
            workspaceName,
            projectUuid,
            taskUuid
        }
    });
