import { contentUserSchema, legacyProfileSchema } from './user.types';
import { itemSchema } from './item.types';
import { clientContactLabelSchema, clientEventLabelSchema } from './clientLabel.types';
import { baseClientSchema } from './client.types';
import { EventIntervalUnit } from './db/enums';
import { PROJECT_STATUS, ROLES } from '../constants/projects.constants';
import { z } from 'zod';
import { Locale } from './enums';

export const eventUserSchema = contentUserSchema.extend({
    locale: z.nativeEnum(Locale),
    timezone: z.string(),
    labels: z.array(clientContactLabelSchema)
});

export type EventUser = z.infer<typeof eventUserSchema>;

export const baseEventReminderSchema = z.object({
    uuid: z.string(),
    datetime: z.date()
});

export type BaseEventReminder = z.infer<typeof baseEventReminderSchema>;

export type EventReminder = BaseEventReminder & {
    email: string;
    user_uuid: string;
    title: string | null;
    description?: string | null;
    event_uuid: string;
    owner_uuid: string;
    client_uuid: string;
    locale: 'en' | 'fr' | 'de';
};

export const eventDaysSchema = z.object({
    monday: z.boolean(),
    tuesday: z.boolean(),
    wednesday: z.boolean(),
    thursday: z.boolean(),
    friday: z.boolean(),
    saturday: z.boolean(),
    sunday: z.boolean()
});

export type EventDays = z.infer<typeof eventDaysSchema>;

export const baseEventSchema = z.object({
    uuid: z.string(),
    title: z.string().nullable(),
    description: z.string().nullable(),
    color: z.string().nullable(),
    startDate: z.date(),
    endDate: z.date(),
    duration: z.number(),
    intervalValue: z.number(),
    intervalUnit: z.nativeEnum(EventIntervalUnit),
    days: eventDaysSchema,
    client: baseClientSchema,
    hasItems: z.boolean().optional(),
    items: z.array(itemSchema)
});

export const herawEventProjectSchema = z.object({
    uuid: z.string(),
    name: z.string(),
    role: z.nativeEnum(ROLES),
    status: z.nativeEnum(PROJECT_STATUS),
    company: z
        .object({
            uuid: z.string(),
            name: z.string(),
            color: z.string().nullable()
        })
        .nullable(),
    user: z
        .object({
            uuid: z.string(),
            email: z.string()
        })
        .merge(legacyProfileSchema),
    color: z.string().nullable().optional()
});

export type HerawEventProject = z.infer<typeof herawEventProjectSchema>;

export const herawEventSchema = baseEventSchema.extend({
    project: herawEventProjectSchema.nullable(),
    owner: eventUserSchema,
    teams: z.array(
        z.object({
            uuid: z.string(),
            name: z.string(),
            color: z.string().nullable()
        })
    ),
    chatRoomUuid: z.string().nullable().optional(),
    chatRoomLink: z.string().nullable().optional(),
    deletedDays: z.array(z.date()).optional(),
    intervalUnit: z.nativeEnum(EventIntervalUnit),
    intervalValue: z.number(),
    users: z.array(eventUserSchema),
    reminders: z.array(baseEventReminderSchema),
    canEditAndDelete: z.boolean(),
    labels: z.array(clientEventLabelSchema)
});

export type HerawEvent = z.infer<typeof herawEventSchema>;

export type ComputedEvent = HerawEvent & {
    computedDates: { startDate: Date; endDate: Date }[];
};

export type EventsState = {
    events: HerawEvent[];
    isLoading: boolean;
    isUpdating: boolean;
    dateRangeUpdating: { start: Date; end: Date } | null;
};
