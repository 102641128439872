import { z } from 'zod';
import { baseUploadPayload } from './asset.payload';
import { sanitizedString } from '@he-novation/utils/zod.utils';

export const noteParamsSchema = z.object({
    workspaceName: z.string(),
    noteUuid: z.string().uuid()
});
export const commentParamsSchema = z.object({
    workspaceName: z.string(),
    noteUuid: z.string().uuid(),
    commentUuid: z.string().uuid()
});

export type NoteFileSpecificParams = z.infer<typeof noteParamsSchema>;

export const noteSpecificParams = z.object({
    noteUuid: z.string().uuid()
});

export type NoteSpecificParams = z.infer<typeof noteSpecificParams>;

export const commentSpecificParams = z.object({
    noteUuid: z.string().uuid(),
    commentUuid: z.string().uuid()
});

export type CommentSpecificParams = z.infer<typeof commentSpecificParams>;
export const noteCreateBody = z.object({
    fileUuid: z.string().uuid(),
    fileVersion: z.number(),
    assignees: z.array(z.string().uuid()).optional().nullable(),
    content: sanitizedString({ html: true }),
    draft: z.boolean(),
    estimatedEndDate: z.coerce.date().nullable().optional(),
    metadata: z.any(),
    notify: z.boolean(),
    teamUuid: z.string().uuid().nullable().optional(),
    type: z.enum(['areaselection', 'timecode', 'global', 'sequence', 'rectangle', 'page']),
    isTask: z.boolean().optional(),
    imageData: z.any(),
    castFileUuid: z.string().uuid().optional(),
    postedAs: z.string().email().optional(),
    recaptcha: z.string().optional(),
    tags: z.array(z.string()).optional()
});

export type NoteCreateBody = z.infer<typeof noteCreateBody>;

export const commentCreateBody = z.object({
    content: sanitizedString({ html: true }),
    draft: z.boolean(),
    notify: z.boolean(),
    postedAs: z.string().email().optional(),
    castFileUuid: z.string().uuid().optional(),
    recaptcha: z.string().optional(),
    tags: z.array(z.string()).optional()
});

export type CommentCreateBody = z.infer<typeof commentCreateBody>;

export const commentEditBody = z.object({
    content: sanitizedString({ html: true, optional: true }),
    draft: z.boolean().optional(),
    tags: z.array(z.string()).optional()
});

export type CommentEditBody = z.infer<typeof commentEditBody>;

export const noteAttachmentParams = z.object({
    noteUuid: z.string().uuid(),
    commentUuid: z.string().uuid()
});

export type NoteAttachmentParams = z.infer<typeof noteAttachmentParams>;

export const noteAttachmentCreateBody = z.object({
    files: z.array(baseUploadPayload),
    fromCast: z.boolean().optional(),
    castFileUuid: z.string().uuid().optional()
});

export type NoteAttachmentCreateBody = z.infer<typeof noteAttachmentCreateBody>;
