import { asyncEntitiesFetch } from '@he-novation/front-shared/async/entity.async';

import { WS_PREFIX_IN } from '$constants/webSocket.constants';
import { asyncActionSuccess } from '$helpers/asyncAction';
export const ENTITIES = 'ENTITIES';
export const ENTITIES_FETCH = `${ENTITIES}/FETCH`;

export const ENTITY_SET_PICTURE_ASSET = `${ENTITIES}/SET_PICTURE_ASSET`;
export const WS_ENTITIES_CREATE = `${WS_PREFIX_IN}${ENTITIES}/CREATE`;
export const WS_ENTITIES_UPDATE = `${WS_PREFIX_IN}${ENTITIES}/UPDATE`;
export const WS_ENTITIES_DELETE = `${WS_PREFIX_IN}${ENTITIES}/DELETE`;

export const entitiesFetch = (workspaceName: string) => async (dispatch) => {
    dispatch({ type: ENTITIES_FETCH });
    const entities = await asyncEntitiesFetch(workspaceName);
    dispatch({ type: asyncActionSuccess(ENTITIES_FETCH), entities });
};

export const entitySetPictureAsset = ({ entityUuid, url, key }) => ({
    type: ENTITY_SET_PICTURE_ASSET,
    entityUuid,
    url,
    key
});

export const companiesSocketActions = {
    sioCompanyCreate: (data) => ({ type: WS_ENTITIES_CREATE, data }),
    sioCompanyUpdate: (data) => ({ type: WS_ENTITIES_UPDATE, data }),
    sioCompanyDelete: (data) => ({ type: WS_ENTITIES_DELETE, data })
};
