import { folderContentSchema, fullFolderSchema } from '../folder.types';
import { z } from 'zod';

export const folderCreateResponse = fullFolderSchema;
export type FolderCreateResponse = z.infer<typeof folderCreateResponse>;

export const folderContentFindResponse = folderContentSchema;
export type FolderContentFindResponse = z.infer<typeof folderContentFindResponse>;
export const folderFindResponse = fullFolderSchema;
export type FolderFindResponse = z.infer<typeof folderFindResponse>;
