import { z } from 'zod';
import { pluginExportFile } from '../plugin.types';

export const pluginUuidSchema = z.object({
    pluginUuid: z.string()
});

export type PluginUuidSchema = z.infer<typeof pluginUuidSchema>;
export const pluginConfigurationSchema = z.object({
    enabled: z.boolean().optional(),
    properties: z.any().optional(),
    secrets: z.any().optional(),
    name: z.string().optional()
});

export type PluginConfigurationSchema = z.infer<typeof pluginConfigurationSchema>;

export const pluginConfigurationCreationSchema = pluginConfigurationSchema
    .omit({ name: true })
    .merge(
        z.object({
            type: z.string(),
            name: z.string()
        })
    );

export type PluginConfigurationCreationSchema = z.infer<typeof pluginConfigurationCreationSchema>;

export const pluginAuthSchema = z.object({
    type: z.string()
});

export type PluginAuthSchema = z.infer<typeof pluginAuthSchema>;

export const pluginExportParams = z.object({
    plugin: z.union([z.literal('youtube'), z.literal('brightcove'), z.literal('vimeo')])
});

export type PluginExportParams = z.infer<typeof pluginExportParams>;

export const pluginExportBody = z.object({
    folderUuid: z.string().uuid(),
    items: z.array(pluginExportFile)
});

export type PluginExportBody = z.infer<typeof pluginExportBody>;
