import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormField } from '@he-novation/design-system/components/form/FormField/FormField';

import Chips from '$components/base/Chips/Chips';
import { useTranslate } from '$hooks/useTranslate';
import { fileUpdate } from '$redux/content/file/fileActions';
import { toggleKeyboardListeners } from '$redux/ui/uiActions';
import { updateUserPreferences } from '$redux/user/userActions';
import { currentUserUuidSelector, preferencesSelector } from '$redux/user/userSelectors';

type FileInfoTagsProps = {
    file: {
        uuid: string;
        version: number;
        tags: string[];
    };
    label?: string;
    readOnly?: boolean;
};

export function FileInfoTags({ file, label, readOnly }: FileInfoTagsProps) {
    const dispatch = useDispatch();

    const { t } = useTranslate();

    const { preferences } = useSelector(preferencesSelector);
    const { currentUserUuid } = useSelector(currentUserUuidSelector);

    const [tags, setTags] = useState(file.tags);
    const [availableTags, setAvailableTags] = useState([
        ...new Set([...(preferences.tags || []), ...file.tags])
    ]);

    const afterRef = useRef<HTMLDivElement>(null);

    if (readOnly) {
        return (
            <>
                <h3>{label}</h3>
                {file.tags.length === 0 && <p>{t('folder.No tags')}</p>}
                {file.tags.map((tag) => (
                    <Chips key={tag}>{tag}</Chips>
                ))}
            </>
        );
    }

    return (
        <FormField
            id={'file-info-tags'}
            type={'react-select'}
            label={label}
            isMulti={true}
            isSearchable={true}
            creatable={true}
            after={<div ref={afterRef} className={'react-select-tags-list'} />}
            multiContainerRef={afterRef}
            onFocus={() => dispatch(toggleKeyboardListeners(false))}
            onBlur={() => dispatch(toggleKeyboardListeners(true))}
            onCreateOption={(value: string) => {
                const newTags = [...tags, value];
                setAvailableTags([...availableTags, value]);
                setTags(newTags);
                dispatch(
                    fileUpdate(file.uuid, file.version, {
                        tags: newTags
                    })
                );
                dispatch(
                    updateUserPreferences(currentUserUuid, {
                        ...preferences,
                        tags: [...(preferences.tags || []), value]
                    })
                );
            }}
            onChange={(e, tags) => {
                setTags(tags);
                dispatch(
                    fileUpdate(file.uuid, file.version, {
                        tags
                    })
                );
            }}
            value={tags}
            options={availableTags.map((tag) => ({
                value: tag,
                label: tag
            }))}
        />
    );
}
