import { buildApiCall } from '@he-novation/config/apis/api-fetch';
import {
    API_AUTH_PASSWORD,
    API_AUTH_SESSION,
    API_AUTH_USER
} from '@he-novation/config/apis/auth-api';
import { API_FOLDER_ACCESS_REQUEST } from '@he-novation/config/apis/folder-api';
import { API_PROJECT_NOTIFICATIONS } from '@he-novation/config/apis/project-api';
import {
    API_USER,
    API_USER_INTEGRATIONS,
    API_USER_LICENSES,
    API_USER_NOTIFICATIONS,
    API_USER_PLUGINS,
    API_USER_PREFERENCES
} from '@he-novation/config/apis/user-api';
import { userPaths } from '@he-novation/config/paths/herawApiPaths';
import { indexLink } from '@he-novation/config/paths/herawFrontUris';
import { signInLink } from '@he-novation/config/paths/herawLoginPaths';
import { AuthPasswordUpdatePayload } from '@he-novation/config/types/payloads/auth.payload';
import {
    UserInfoSchema,
    UserNotificationSettingsSchema,
    UserPreferencesSchema
} from '@he-novation/config/types/payloads/user.payload';
import { PublicPluginConfigurationType } from '@he-novation/config/types/plugin.types';
import { UserAppInfos, type UserLicense, UserStorage } from '@he-novation/config/types/user.types';
import { load } from 'recaptcha-v3';
import { apiFetch } from './apiFetch';

export const asyncOIDCLogin = (username: string, password: string, provider: string) =>
    apiFetch(userPaths.oidcLogin, {
        method: 'POST',
        params: {
            provider
        },
        body: {
            username,
            password
        }
    });
export const asyncUserLogin = (email: string, password: string, twoFAKey?: string) =>
    buildApiCall(API_AUTH_SESSION.POST)({
        body: {
            email,
            password,
            totp: twoFAKey
        }
    });

export const fetchUserAppInfos = (): Promise<UserAppInfos> =>
    buildApiCall(API_USER.GET_INFOS)({}).then((r) => ({
        ...r,
        created: new Date(r.created),
        passwordReset: r.passwordReset ? new Date(r.passwordReset) : null
    }));

export const requestDelete = (userUuid: string, cb?: () => void) =>
    buildApiCall(API_USER.DELETE)({
        params: { userUuid }
    }).then(() => typeof cb === 'function' && cb());

export const paymentCancelSubscription = () =>
    apiFetch(`api/payment/cancel/subscription`, {
        method: 'POST'
    });

export const fetchAccount = () => buildApiCall(API_USER.GET)({});

export const userLicensesFetch = (): Promise<UserLicense[]> =>
    buildApiCall(API_USER_LICENSES.LIST)({});

export const fetchStorage = async (folderUuid?: string, sum?: boolean): Promise<UserStorage> =>
    buildApiCall(API_USER.GET_STORAGE)({ query: { folderUuid, sum } });

export const fetchPlugins = (options?: { signal?: AbortSignal }): Promise<string[]> =>
    buildApiCall(API_USER_PLUGINS.LIST)({ options });

export const fetchPluginsEnabled = (): Promise<PublicPluginConfigurationType[]> =>
    buildApiCall(API_USER_INTEGRATIONS.LIST)({});

export const updateProfile = async (userUuid: string, body: UserInfoSchema) =>
    buildApiCall(API_USER.PATCH)({
        params: { userUuid },
        body
    });

export const updatePassword = async (body: AuthPasswordUpdatePayload) =>
    buildApiCall(API_AUTH_PASSWORD.PUT)({ body });

export const updateUserPreferences = async (userUuid: string, body: UserPreferencesSchema) =>
    buildApiCall(API_USER_PREFERENCES.PATCH)({
        params: { userUuid },
        body
    });

export const fetchGeoIp = async () =>
    window
        .fetch('https://api.ipstack.com/check?access_key=3098b60efb362a9194cf15bb592954c3')
        .then((r) => r.json());

export const cancelLicense = (userUuid: string) =>
    buildApiCall(API_USER_LICENSES.DELETE)({
        body: { userUuid }
    });

export const signUp = async ({
    firstname,
    lastname,
    email,
    phone,
    firm,
    role,
    recaptcha,
    locale,
    plan,
    picture
}: {
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    firm: string;
    role: string;
    recaptcha: string;
    locale: string;
    plan: string;
    picture?: string;
}) =>
    buildApiCall(API_USER.POST)({
        body: {
            firstname,
            lastname,
            email,
            phone,
            firm,
            role,
            recaptcha,
            locale,
            plan,
            picture
        }
    })
        .then(() => {
            window.location.href = process.env.APP_URL + indexLink();
        })
        .catch((e) => {
            window.location.href = signInLink(locale, {
                email,
                error: typeof e.data === 'string' ? e.data : e.data?.message
            });
        });

export const logout = (locale?: string) =>
    buildApiCall(API_AUTH_SESSION.DELETE)({}).then(() => {
        window.location.href = signInLink(locale);
    });

export const requestAdmittance = (workspaceName: string, { signupData, folderUuid }) =>
    buildApiCall(API_FOLDER_ACCESS_REQUEST.POST)({
        params: { workspaceName, folderUuid },
        body: {
            signupData
        }
    });

export const asyncTranslationCreditsFetch = () =>
    buildApiCall(API_USER.GET_TRANSLATION_CREDITS)({});

export const asyncEmailVerify = async (email: string, password?: string | null) => {
    const recaptchaInstance = await load(process.env.RECAPTCHA_FRONT_KEY!);
    const recaptcha = await recaptchaInstance.execute('verifyEmailOrPassword');
    return buildApiCall(API_USER.VERIFY_EMAIL)({
        body: {
            email,
            password,
            recaptcha
        }
    });
};

export const asyncOpenApiUserFetch = async (email: string, password: string) =>
    buildApiCall(API_AUTH_USER.GET_POST_OA_DEPRECATED)({
        body: {
            email,
            password
        }
    });

export function asyncUserNotificationSettingsFetch(
    workspaceName: string
): Promise<UserNotificationSettingsSchema> {
    return buildApiCall(API_USER_NOTIFICATIONS.GET)({ params: { workspaceName } });
}

export function asyncDefaultProjectUserNotificationSettingsFetch(
    workspaceName: string
): Promise<UserNotificationSettingsSchema | null> {
    return buildApiCall(API_PROJECT_NOTIFICATIONS.GET_DEFAULT)({
        params: { workspaceName }
    });
}

export function asyncDefaultProjectUserNotificationSettingsPut(
    workspaceName: string,
    body: UserNotificationSettingsSchema
): Promise<null> {
    return buildApiCall(API_PROJECT_NOTIFICATIONS.PUT_DEFAULT)({
        params: { workspaceName },
        body
    });
}

export function asyncProjectUserNotificationSettingsFetch(
    workspaceName: string,
    projectUuid: string
): Promise<UserNotificationSettingsSchema | null> {
    return buildApiCall(API_PROJECT_NOTIFICATIONS.GET)({
        params: {
            workspaceName,
            projectUuid
        }
    });
}

export function asyncUserNotificationSettingsPut(
    workspaceName: string,
    projectUuid: string | null,
    body: UserNotificationSettingsSchema
): Promise<null> {
    if (!projectUuid) {
        return buildApiCall(API_USER_NOTIFICATIONS.PUT)({
            params: { workspaceName },
            body
        });
    }

    return buildApiCall(API_PROJECT_NOTIFICATIONS.PUT)({
        params: { workspaceName, projectUuid },
        body
    });
}

export function asyncProjectUserNotificationSettingsPutAll(
    workspaceName: string,
    body: UserNotificationSettingsSchema
): Promise<null> {
    return buildApiCall(API_PROJECT_NOTIFICATIONS.PUT_ALL)({
        params: { workspaceName },
        body
    });
}
