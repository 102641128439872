import { z } from 'zod';
import { userClientRightsSchema, UserFileType } from '../user.types';

export const oaUserSchema = z.object({
    uuid: z.string().uuid(),
    email: z.string().email(),
    clientRights: userClientRightsSchema,
    apiKey: z.string().uuid()
});

export type OAUser = z.infer<typeof oaUserSchema>;

export const oaUserFileSchema = z.object({
    uid: z.string(),
    type: z.nativeEnum(UserFileType).nullable(),
    key: z.string().nullable(),
    url: z.string().nullable(),
    urlExpires: z.date().nullable(),
    thumbnailUrl: z.string().nullable(),
    isPrivate: z.boolean(),
    clientName: z.string().optional().nullable(),
    bucketName: z.string(),
    userUuid: z.string().uuid().optional().nullable()
});
export type OAUserFile = z.infer<typeof oaUserFileSchema>;
