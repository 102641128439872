import styles from './SidePanelCalendarFilters.module.css';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormField } from '@he-novation/design-system/components/form/FormField/FormField';
import { Title } from '@he-novation/design-system/components/text/Title/Title';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import {
    calendarActiveGrouperFilters,
    calendarGrouperAtom,
    calendarItemColorTypeAtom,
    calendarProjectAtom
} from '$atoms/calendar.atoms';
import { FilterCheckboxTag } from '$components/Filters/FilterCheckboxes';
import {
    CalendarItemColorType,
    CalendarPreferences,
    ScrollCalendarGrouper
} from '$components/ScrollCalendar/ScrollCalendar.types';
import { ScrollCalendarFilters } from '$components/ScrollCalendar/ScrollCalendarFilters/ScrollCalendarFilters';
import { usePanel } from '$hooks/usePanel';
import { useTranslate } from '$hooks/useTranslate';
import { set } from '$redux/route/routeActions';
import { updateUserPreferences } from '$redux/user/userActions';
import { currentUserUuidSelector, preferencesSelector } from '$redux/user/userSelectors';

export function SidePanelCalendarFilters() {
    const { t } = useTranslate();
    const [currentGrouper, setCurrentGrouper] = useAtom(calendarGrouperAtom);
    const setGrouperFilters = useSetAtom(calendarActiveGrouperFilters);
    const [itemColorType, setItemColorType] = useAtom(calendarItemColorTypeAtom);
    const project = useAtomValue(calendarProjectAtom);
    const dispatch = useDispatch();
    const { preferences } = useSelector(preferencesSelector);
    const isInProject = !!project;
    const { closePanel } = usePanel();
    const { currentUserUuid } = useSelector(currentUserUuidSelector);

    const grouperOptions = [
        { value: ScrollCalendarGrouper.Users, label: t('common.Users') },
        { value: ScrollCalendarGrouper.Items, label: t('common.Items') },
        { value: ScrollCalendarGrouper.Labels, label: t('calendar.ClientEventLabels') }
    ];
    if (!isInProject) {
        grouperOptions.push({
            value: ScrollCalendarGrouper.Projects,
            label: t('calendar.Projects')
        });
    }

    const eventColorOptions = Object.values(CalendarItemColorType).map((color) => ({
        value: color,
        label: t(`calendar.${color}`)
    }));

    const handleUpdateUserPreferences = <T extends keyof CalendarPreferences>(
        key: T,
        value: CalendarPreferences[T]
    ) => {
        const updatedCalendarPreferences = {
            ...preferences,
            calendar: {
                ...preferences.calendar,
                [key]: value
            }
        };
        dispatch(updateUserPreferences(currentUserUuid, updatedCalendarPreferences));
    };

    const handleOnChangeGrouper = (_e, grouper: ScrollCalendarGrouper) => {
        setGrouperFilters([]);
        setCurrentGrouper(grouper);
        handleUpdateUserPreferences('grouper', grouper);
        // update the query param grouper
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set('grouper', grouper);
        dispatch(set(window.location.pathname + '?' + queryParams.toString(), true, true));
    };

    const handleItemColorTypeChange = (_e, type: CalendarItemColorType) => {
        setItemColorType(type);
        handleUpdateUserPreferences('itemColorType', type);
    };

    return (
        <div>
            <div onClick={closePanel}>
                <Title className={styles.title} icon={'chevron'} tag={'h2'} type="tab">
                    {t('common.Filters')}
                </Title>
            </div>

            <FormField
                className={styles.select}
                id="grouper"
                label={t('calendar.Group By')}
                value={currentGrouper}
                onChange={handleOnChangeGrouper}
                type="react-select"
                options={grouperOptions}
            />
            <ScrollCalendarFilters tag={FilterCheckboxTag.Accordion} className={styles.filters} />
            {!isInProject && (
                <FormField
                    className={styles.select}
                    id="grouper"
                    label={t('calendar.Event Color')}
                    value={itemColorType}
                    onChange={handleItemColorTypeChange}
                    type="react-select"
                    options={eventColorOptions}
                />
            )}
        </div>
    );
}
