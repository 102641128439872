import { buildApiCall } from '@he-novation/config/apis/api-fetch';
import {
    Auth2FAUpdatePayload,
    AuthPasswordResetPayload
} from '@he-novation/config/types/payloads/auth.payload';
import { API_AUTH_2FA, API_AUTH_PASSWORD, API_AUTH_SESSION } from '../../config/apis/auth-api';

export const asyncAuthRefresh = () => buildApiCall(API_AUTH_SESSION.GET)({});

export const async2FAUpdate = (body: Auth2FAUpdatePayload) =>
    buildApiCall(API_AUTH_2FA.PUT)({ body });

export const asyncSendPasswordResetToken = (email: string) =>
    buildApiCall(API_AUTH_PASSWORD.TOKEN_POST)({ body: { email } });

export const asyncResetPassword = ({ email, resetToken, newPassword }: AuthPasswordResetPayload) =>
    buildApiCall(API_AUTH_PASSWORD.RESET)({ body: { email, resetToken, newPassword } });
