import { buildApiCall } from '@he-novation/config/apis/api-fetch';
import {
    API_EVENT_DELETED_DAYS,
    API_EVENT_REMINDERS,
    API_EVENTS
} from '@he-novation/config/apis/event-api';
import { HerawEvent } from '@he-novation/config/types/event.types';
import { EventCreateBody, EventUpdateBody } from '@he-novation/config/types/payloads/event.payload';

export async function asyncEventsFetch(
    workspaceName: string,
    start?: Date,
    end?: Date,
    projectUuid?: string
): Promise<HerawEvent[]> {
    return await buildApiCall(API_EVENTS.LIST)({
        params: { workspaceName },
        query: {
            start,
            end,
            projectUuid
        }
    }).then((events) => events.map((e: HerawEvent) => mapEvent(e)));
}

export function asyncEventCreate(
    workspaceName: string,
    body: EventCreateBody
): Promise<HerawEvent> {
    return buildApiCall(API_EVENTS.POST)({
        params: { workspaceName },
        body: body
    });
}

export async function asyncEventDelete(workspaceName: string, eventUuid: string): Promise<void> {
    await buildApiCall(API_EVENTS.DELETE)({
        params: {
            workspaceName,
            eventUuid
        }
    });
}

export async function asyncEventUpdate(
    workspaceName: string,
    eventUuid: string,
    body: EventUpdateBody
): Promise<void> {
    return await buildApiCall(API_EVENTS.PUT)({
        params: { workspaceName, eventUuid },
        body
    });
}

export function asyncEventCreateDeletedDay(
    workspaceName: string,
    eventUuid: string,
    date: Date
): Promise<HerawEvent> {
    return buildApiCall(API_EVENT_DELETED_DAYS.POST)({
        params: {
            workspaceName,
            eventUuid
        },
        body: {
            date
        }
    });
}

export function mapEvent(event: HerawEvent): HerawEvent {
    const startDate = new Date(event.startDate);

    const endDate = new Date(event.endDate);

    event.reminders =
        event.reminders?.map(({ datetime, uuid }) => ({
            uuid,
            datetime: new Date(datetime)
        })) || [];

    if (event.deletedDays) {
        event.deletedDays = event.deletedDays.map((date) => new Date(date));
    }

    return { ...event, startDate, endDate };
}

export async function asyncEventReminderCreate(
    workspaceName: string,
    eventUuid: string,
    datetime: Date
): Promise<{ insertUuid: string }> {
    return await buildApiCall(API_EVENT_REMINDERS.POST)({
        params: { workspaceName, eventUuid },
        body: {
            datetime
        }
    });
}

export async function asyncEventReminderDelete(
    workspaceName: string,
    eventUuid: string,
    reminderUuid: string
): Promise<void> {
    await buildApiCall(API_EVENT_REMINDERS.DELETE)({
        params: {
            workspaceName,
            eventUuid,
            reminderUuid
        }
    });
}
