import { z } from 'zod';
import { assetSchema } from '../asset.types';
import { FileStatus } from '../db/enums';
import { oaWorkspaceNameAndProjectUuidSchema } from './project.open-api.types';

export const oaFileSchema = z.object({
    uuid: z.string(),
    name: z.string(),
    created: z.date().nullable(),
    updated: z.date().nullable(),
    version: z.number(),
    metadata: z.any().nullable(),
    site: z.string(),
    tags: z.string().array(),
    status: z.nativeEnum(FileStatus),
    folder: z.object({
        uuid: z.string(),
        name: z.string()
    }),
    project: z
        .object({
            uuid: z.string(),
            name: z.string()
        })
        .nullable()
        .optional(),
    assets: z.array(assetSchema),
    user: z.object({
        uuid: z.string(),
        email: z.string(),
        locale: z.string(),
        firstname: z.string().optional().nullable(),
        lastname: z.string().optional().nullable()
    }),
    creator: z.object({
        uuid: z.string(),
        email: z.string(),
        firstname: z.string().optional().nullable(),
        lastname: z.string().optional().nullable()
    }),
    client: z.object({
        uuid: z.string(),
        name: z.string()
    }),
    frameRate: z.number().optional(),
    finals: z.array(
        z.object({
            user: z.object({
                uuid: z.string(),
                email: z.string()
            })
        })
    ),
    resolution: z.array(z.number()).optional(),
    metadataAle: z.any().optional(),
    duration: z.number().optional(),
    castFiles: z.array(
        z.object({
            uuid: z.string(),
            castUid: z.string(),
            castName: z.string(),
            castFolderUuid: z.string().nullable().optional()
        })
    ),
    notes: z.number(),
    expires: z.date().nullable()
});
export type OAFile = z.infer<typeof oaFileSchema>;

export const oaFileUuidSchema = z.object({
    fileUuid: z.string().uuid()
});

export type OAFileUuidSchema = z.infer<typeof oaFileUuidSchema>;

export const oaFileSpecificParams = z.object({
    fileUuid: z.string().uuid(),
    fileVersion: z.coerce.number()
});

export type OAFileSpecificParams = z.infer<typeof oaFileSpecificParams>;

export const oaFileVersionAddBody = z.object({
    fileUuid: z.string().uuid()
});

export type OAFileVersionAddBody = z.infer<typeof oaFileVersionAddBody>;

export const oaFileCreateBody = z.object({
    folderUuid: z.string().uuid(),
    mimeType: z.string(),
    size: z.number(),
    version: z.number(),
    name: z.string(),
    uploadGroup: z.string()
});

export type OAFileCreateBody = z.infer<typeof oaFileCreateBody>;

export const oaContentFileSchema = z
    .object({
        folder: z.object({
            uuid: z.string(),
            name: z.string()
        }),
        project: z
            .object({
                uuid: z.string(),
                name: z.string()
            })
            .nullable()
            .optional(),
        assets: z.array(assetSchema),
        user: z.object({
            uuid: z.string(),
            email: z.string(),
            locale: z.string(),
            firstname: z.string(),
            lastname: z.string()
        }),
        client: z.object({
            uuid: z.string(),
            name: z.string()
        }),
        frameRate: z.number().optional(),
        site: z.string(),
        finals: z.array(
            z.object({
                user: z.object({
                    uuid: z.string(),
                    email: z.string()
                })
            })
        ),
        resolution: z.tuple([z.number(), z.number()]).optional(),
        metadata: z.any().optional(),
        metadataAle: z.any().optional(),
        duration: z.number().optional(),
        castFiles: z.array(
            z.object({
                uuid: z.string(),
                castUid: z.string(),
                castName: z.string(),
                castFolderUuid: z.string().nullable().optional()
            })
        ),
        notes: z.number(),
        expires: z.date().nullable()
    })
    .merge(oaFileSchema)
    .omit({ folder: true });
export type OAContentFile = z.infer<typeof oaContentFileSchema>;

export const oaFileCreateResponse = z.object({
    file: oaContentFileSchema,
    asset: assetSchema,
    uploadId: z.string(),
    links: z.array(
        z.object({
            part: z.number(),
            url: z.string(),
            size: z.number()
        })
    )
});
export type OAFileCreateResponse = z.infer<typeof oaFileCreateResponse>;

export const oaFileStatusUpdateBody = z.object({
    status: z.nativeEnum(FileStatus)
});

export type OAFileStatusUpdateBody = z.infer<typeof oaFileStatusUpdateBody>;

export const oaWorkspaceNameAndFileUuidSchema = z.object({
    workspaceName: z.string(),
    fileUuid: z.string().uuid()
});

export type OAWorkspaceNameAndFileUuidSchema = z.infer<typeof oaWorkspaceNameAndFileUuidSchema>;
