import { buildApiCall } from '@he-novation/config/apis/api-fetch';
import { ROLES } from '@he-novation/config/constants/projects.constants';
import { TEAM_STATUS } from '@he-novation/config/constants/teams.constants';
import paths, { pathWithParams, projectPaths } from '@he-novation/config/paths/herawApiPaths';
import type { ProjectFolderTree } from '@he-novation/config/types/folder.types';
import {
    FolderConvertToProjectBody,
    ProjectCreateBody,
    ProjectUpdateBody
} from '@he-novation/config/types/payloads/project.payload';
import { Member, TeamUpdateBody } from '@he-novation/config/types/payloads/team.payload';
import { Project, ProjectStats } from '@he-novation/config/types/project.types';
import { Task } from '@he-novation/config/types/task.types';
import { Team, TeamWithMembers } from '@he-novation/config/types/team.types';
import { isoStringToUTCDate, objectIsoStringsToDates } from '@he-novation/utils/datetime';
import {
    API_PROJECT,
    API_PROJECT_EVENT_LABELS,
    API_PROJECT_TASKS
} from '../../config/apis/project-api';
import { API_TEAM, API_TEAM_USER } from '../../config/apis/team-api';
import { apiFetch } from './apiFetch';
import { download } from './file.async';
import { mapFetchedTask } from './task.async';

export function mapFetchedProject(project: Project): Project {
    if (typeof project.created === 'string') project.created = new Date(project.created);
    if (typeof project.updated === 'string') project.updated = new Date(project.updated);
    if (typeof project.startDate === 'string') project.startDate = new Date(project.startDate);
    if (typeof project.endDate === 'string') project.endDate = new Date(project.endDate);
    return project;
}

export const asyncProjectCreate = (workspaceName: string, body: ProjectCreateBody) =>
    buildApiCall(API_PROJECT.POST)({
        params: { workspaceName },
        body
    });

export const asyncProjectUpdate = (
    workspaceName: string,
    projectUuid: string,
    body: ProjectUpdateBody
) =>
    buildApiCall(API_PROJECT.PATCH)({
        params: { workspaceName, projectUuid },
        body
    });

export const asyncProjectDelete = (workspaceName: string, projectUuid: string) =>
    buildApiCall(API_PROJECT.DELETE)({
        params: { workspaceName, projectUuid }
    });

export const deleteMember = (
    workspaceName: string,
    projectUuid: string,
    teamUuid: string,
    userUuid: string
) => {
    return buildApiCall(API_TEAM_USER.DELETE)({
        params: { workspaceName, projectUuid, teamUuid, userUuid }
    });
};

export const asyncProjectsFetch = (workspaceName: string): Promise<Project[]> =>
    buildApiCall(API_PROJECT.LIST)({
        params: {
            workspaceName
        }
    }).then((projects) =>
        projects.map(objectIsoStringsToDates(['created', 'updated', 'startDate', 'endDate']))
    );

export const fetchProject = (workspaceName: string, projectUuid: string): Promise<Project> =>
    buildApiCall(API_PROJECT.GET)({
        params: { workspaceName, projectUuid }
    }).then((p) => {
        p = objectIsoStringsToDates(['created', 'updated', 'startDate', 'endDate'])(p);
        p.tasks = p.tasks.map((t) => ({
            ...t,
            created: new Date(t.created),
            updated: new Date(t.updated || t.created),
            estimatedEndDate: t.estimatedEndDate && isoStringToUTCDate(t.estimatedEndDate)
        }));
        return p;
    });

export const asyncProjectFolderTreeFetch = (
    workspaceName: string,
    projectUuid: string
): Promise<ProjectFolderTree> =>
    buildApiCall(API_PROJECT.FOLDER_TREE_GET)({
        params: { workspaceName, projectUuid }
    });

export const fetchProjectTasks = (workspaceName: string, projectUuid: string): Promise<Task[]> =>
    buildApiCall(API_PROJECT_TASKS.LIST)({
        params: { workspaceName, projectUuid }
    }).then((tasks) => tasks.map((t) => mapFetchedTask(t)));

export const asyncProjectTeamsFetch = (
    workspaceName: string,
    projectUuid: string
): Promise<TeamWithMembers[]> =>
    buildApiCall(API_TEAM.LIST)({
        params: { workspaceName, projectUuid }
    });

export const asyncTeamCreate = (
    workspaceName: string,
    projectUuid: string,
    body: {
        name: string;
        color?: string;
        castTeamAccess?: boolean;
    }
): Promise<Team> =>
    buildApiCall(API_TEAM.POST)({
        params: {
            workspaceName,
            projectUuid
        },
        body
    });

export const asyncTeamUsersCreate = (
    workspaceName: string,
    projectUuid: string,
    teamUuid: string,
    members: Member[],
    message?: string
): Promise<TeamWithMembers> =>
    buildApiCall(API_TEAM_USER.POST)({
        params: { workspaceName, projectUuid, teamUuid },
        body: {
            members,
            message
        }
    });

export const asyncProjectMemberUpdate = (
    workspaceName: string,
    projectUuid: string,
    teamUuid: string,
    userUuid: string,
    {
        role,
        download,
        metadata
    }: { role?: ROLES; download?: boolean; metadata?: { favorite?: boolean } }
) =>
    buildApiCall(API_TEAM_USER.PATCH)({
        params: { workspaceName, projectUuid, teamUuid, userUuid },
        body: {
            role,
            download,
            metadata
        }
    });

export const deleteTeam = (workspaceName: string, teamUuid: string, projectUuid: string) =>
    buildApiCall(API_TEAM.PATCH)({
        params: { workspaceName, teamUuid, projectUuid },
        body: {
            status: TEAM_STATUS.HIDDEN
        }
    });

export const asyncTeamUpdate = (
    workspaceName: string,
    projectUuid: string,
    teamUuid: string,
    { name, castTeamAccess, color }: TeamUpdateBody
): Promise<Team> =>
    buildApiCall(API_TEAM.PATCH)({
        params: { workspaceName, projectUuid, teamUuid },
        body: {
            name,
            castTeamAccess,
            color
        }
    });

export const asyncFetchProjectClientEventLabels = (workspaceName: string, projectUuid: string) =>
    buildApiCall(API_PROJECT_EVENT_LABELS.LIST)({
        params: { workspaceName, projectUuid }
    });

export const asyncProjectStatsFetch = (
    workspaceName: string,
    projectUuid: string
): Promise<ProjectStats> =>
    buildApiCall(API_PROJECT.STATS_GET)({
        params: { workspaceName, projectUuid }
    }).then(objectIsoStringsToDates(['updated']));

export const asyncProjectStatsDownload = (
    workspaceName: string,
    projectUuid: string,
    name: string
) =>
    buildApiCall(API_PROJECT.STATS_XLSX_GET)({
        params: { workspaceName, projectUuid }
    }).then(async (r) => download(URL.createObjectURL(await r.blob()), name));

export function asyncProjectCreateFromFolder(body: FolderConvertToProjectBody) {
    return apiFetch(projectPaths.fromFolder, {
        method: 'POST',
        body
    });
}
