import { buildApiCall } from '@he-novation/config/apis/api-fetch';
import { API_SUBTITLE, API_SUBTITLE_ENTRY } from '@he-novation/config/apis/subtitle-api';
import { SUBTITLE_MAX_SIZE } from '@he-novation/config/constants/uploads.constants';
import { pathWithParams, publicApiV1Paths } from '@he-novation/config/paths/herawApiPaths';
import { Asset } from '@he-novation/config/types/asset.types';
import {
    SubtitleEntryBody,
    SubtitleQuerySchema,
    SubtitleUpdateSchema,
    SubtitleUploadUrlBody
} from '@he-novation/config/types/payloads/subtitle.payload';
import { SubtitleUploadUrlResponse } from '@he-novation/config/types/responses/subtitle.responses';
import { SubtitlesMimeType, subtitlesMimeTypes } from '@he-novation/config/types/transcoding';
import { isBinary } from '../utils/isBinary';
import { apiFetch } from './apiFetch';
import { readMagicBytes } from './file.async';

export const subtitleCreate = (
    workspaceName: string,
    { fileUuid, fileVersion, locale, name, copy }
) =>
    buildApiCall(API_SUBTITLE.POST)({
        params: {
            workspaceName
        },
        body: {
            fileUuid,
            fileVersion,
            locale,
            name,
            copy
        }
    });

export const subtitleEntryCreate = (
    workspaceName: string,
    subtitleUuid: string,
    data: SubtitleEntryBody
) =>
    buildApiCall(API_SUBTITLE_ENTRY.POST)({
        params: {
            workspaceName,
            subtitleUuid
        },
        body: data
    });

export const subtitleEntryDelete = (
    worspaceName: string,
    subtitleUuid: string,
    entryUuid: string
) =>
    buildApiCall(API_SUBTITLE_ENTRY.DELETE)({
        params: {
            workspaceName: worspaceName,
            subtitleUuid,
            entryUuid
        }
    });

export const subtitleEntryUpdate = (
    workspaceName: string,
    subtitleUuid: string,
    entryUuid: string,
    body: SubtitleUpdateSchema
) =>
    buildApiCall(API_SUBTITLE_ENTRY.PUT)({
        params: {
            workspaceName,
            subtitleUuid,
            entryUuid
        },
        body
    });

export async function asyncCheckSubtitlesFileFormat(file: File) {
    if (file.size > SUBTITLE_MAX_SIZE) {
        throw new Error('file too big', {
            cause: 'common.File is too big'
        });
    }

    const serverMimeType = await readMagicBytes(file);

    if (!serverMimeType || !subtitlesMimeTypes.includes(serverMimeType as SubtitlesMimeType)) {
        throw new Error('unsupported subtitle format', {
            cause: 'subtitles.Unsupported subtitle format'
        });
    }
}

export async function asyncInitSubtitlesFileUpload(
    workspaceName: string,
    fileUuid: string,
    fileVersion: number,
    locale: string,
    file: File
): Promise<SubtitleUploadUrlResponse> {
    return (await buildApiCall(API_SUBTITLE.UPLOAD_POST)({
        params: {
            workspaceName
        },
        body: {
            fileUuid,
            fileVersion,
            locale,
            subtitleFileName: file.name,
            subtitleFileSize: file.size,
            isBinary: await isBinary(file)
        }
    })) satisfies SubtitleUploadUrlBody;
}

export const deleteSubtitles = (workspaceName: string, subtitleUuid: string) =>
    buildApiCall(API_SUBTITLE.DELETE)({
        params: {
            workspaceName,
            subtitleUuid
        }
    });

export const asyncOpenApiSubtitleFetch = (
    apiKey: string,
    subtitleUuid: string,
    query: SubtitleQuerySchema,
    rawResponse?: boolean
) =>
    buildApiCall(API_SUBTITLE.GET_OA_DEPRECATED)({
        options: {
            headers: {
                Authorization: 'Bearer ' + apiKey
            },
            rawResponse
        },
        params: {
            subtitleUuid
        },
        query
    });

/**
 * Returns null if export has not been generated, other returns the asset with its url
 */
export const asyncBurnSubtitles = async (
    workspaceName: string,
    fileUuid: string,
    fileVersion: number,
    subtitleUuid: string
): Promise<Asset | null> =>
    buildApiCall(API_SUBTITLE.BURN_POST)({
        params: {
            workspaceName,
            fileUuid,
            fileVersion,
            subtitleUuid
        }
    });
