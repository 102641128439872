import { z } from 'zod';
import { Locale } from './i18n.types';
import {
    BaseClientLabel,
    ClientCastLabel,
    ClientContactLabel,
    ClientEventLabel,
    ClientItemLabel,
    ClientLabelType
} from './clientLabel.types';
import { DefaultCastStyle } from './cast.types';

export const baseClientSchema = z.object({
    uuid: z.string(),
    name: z.string()
});

export type BaseClientType = z.infer<typeof baseClientSchema>;

export type Client = {
    created: Date;
    defaultCastStyle: DefaultCastStyle;
    enable_stripe_payment: boolean;
    licenses: number;
    name: string;
    password_reset: Date | null;
    profile?: { firstname?: string; lastname?: string; email: string };
    plugins?: string[];
    storage: {
        total: number;
    };
    tokens: number;
    uuid: string;
};

export enum ClientUserRole {
    ADMINISTRATOR = 'ADMINISTRATOR',
    BASIC = 'BASIC',
    USER = 'USER'
}

export type ClientUser = {
    type: 'member';
    apiKey: string;
    clientRights?: {
        castAdmin?: boolean;
        companyAdd?: boolean;
        contactAdd?: boolean;
        contactAdmin?: boolean;
        itemAdd?: boolean;
        projectAdd?: boolean;
        projectAdmin?: boolean;
    };
    clientRole: ClientUserRole;
    created: Date;
    deleteRequested?: Date | null;
    email: string;
    expires?: Date | null;
    firstname?: string | null;
    lastname?: string | null;
    storage: { total: number; used: number };
    translationCredits: number;
    updated: Date | null;
    uuid: string;
    lastUsed: Date | null;
    picture?: string | null;
    licenseUuid: string | null;
    firm?: string;
    phone?: string;
    role?: string;
};

export type ClientGuest = {
    type: 'guest';
    assignedBy: {
        uuid: string;
        email: string;
    };
    email: string;
    firstname?: string | null;
    lastname?: string | null;
    firm?: string | null;
    folders: number;
    licenseUuid: string;
    projects: number;
    uuid: string;
    last_used: Date | null;
};

export type ClientState = {
    labels: {
        [ClientLabelType.Event]: ClientEventLabel[];
        [ClientLabelType.Contact]: ClientContactLabel[];
        [ClientLabelType.Item]: ClientItemLabel[];
        [ClientLabelType.Cast]: ClientCastLabel[];
    };
    translationCredits: number;
    translationCreditsTotal: number;
    created?: Date;
    password_reset?: Date;
    storage?: ClientStorage;
    uuid?: string;
    name?: string;
    account?: string;
    storage_expires?: Date | null;
    tokens?: number;
    licenses?: number;
    profile?: any;
    users: ClientUser[];
    guests: ClientGuest[];
    preferences: ClientPreferences;
    defaultCastStyle?: DefaultCastStyle;
};

export type ClientOIDC = {
    issuer: string;
    clientId: string;
    clientSecret?: string | null;
    PKCE: boolean;
    button?: string | null;
    strict: boolean;
};

export type ClientStorage = { total: number; used: number; available: number };

export type ClientDomain = {
    uuid: string;
    name: string;
    created: Date | null;
    updated: Date | null;
    confirmationPassed: boolean;
    confirmationExpires: Date | null;
    confirmationUuid: string;
};

export const DEFAULT_CLIENT_PREFERENCES = {
    locale: 'en',
    user_new: {
        storage: -1
    },
    password: {
        constraints: {
            min_length: 8,
            min_lower: 1,
            min_number: 1,
            min_special: 0,
            min_upper: 1
        },
        expires: false,
        expires_days: 0
    }
};

export type ClientPreferences = {
    client_uuid?: string;
    client_name?: string;
    disableAllDownloads?: boolean;
    forceTotp?: boolean;
    ipAddress?: string;
    locale?: Locale;
    mail?: {
        reset_password?: boolean;
    };
    mail_prefix?: string;
    password?: {
        constraints?: {
            min_length: number;
            min_lower: number;
            min_number: number;
            min_special: number;
            min_upper: number;
        };
        expires?: boolean;
        expires_days?: number;
    };
    projects?: {
        castTeamAccess?: boolean;
        disableProjectCasts?: boolean;
    };
    rights?: {
        final_add_version?: boolean;
        comments_modify?: boolean;
        comments_modify_timeout?: number;
    };
    translationCredits?: number;
    translationCreditsTotal?: number;
    totp_enabled?: number;
    user_default?: {
        mail: {
            notify_digest: string;
        };
        livenotify_desktop: boolean;
        livenotify_email: boolean;
        livenotify: {
            access_grant: boolean;
            comment_add: boolean;
            file_download: boolean;
            file_export: boolean;
            file_new: boolean;
            file_set_final: boolean;
        };
    };
    user_new?: {
        storage?: number;
    };
};

export type ClientStats = {
    users: number;
    users_included: number;
    projects: number;
    tasks: number;
    files: number;
    collaborators: number;
};
