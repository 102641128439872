import { z } from 'zod';
import { ApiMethod } from '../types/api.types';
import {
    oaProjectParamsSchema,
    oaProjectUuidAndUserUuidSchema,
    oaProjectUuidSchema,
    oaTeamSchema,
    oaTeamWithMembersSchema
} from '../types/open-api/project.open-api.types';
import {
    oaTeamParamsSchema,
    oaTeamPostBody,
    oaTeamUsersPostBody
} from '../types/open-api/team.open-api.types';
import { projectParamsSchema } from '../types/payloads/project.payload';
import {
    teamParamsSchema,
    teamPostBody,
    teamPutBody,
    teamUserParamsSchema,
    teamUserPatchBody,
    teamUsersPostBody
} from '../types/payloads/team.payload';
import { teamSchema } from '../types/team.types';
import { Group } from '../paths/herawApiPathGroups';

export const API_TEAM = {
    LIST: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/projects/:projectUuid/teams',
        handlerMethod: 'getTeams' as 'getTeams',
        zod: {
            params: projectParamsSchema
        },
        responses: {
            200: {
                description: 'List of project teams',
                schema: z.array(teamSchema)
            }
        }
    },
    LIST_OA: {
        method: ApiMethod.GET,
        path: '/public/v1/workspaces/:workspaceName/projects/:projectUuid/teams',
        handlerMethod: 'getTeams' as 'getTeams',
        zod: {
            params: oaProjectParamsSchema
        },
        responses: {
            200: {
                description: 'List of project teams',
                schema: z.array(oaTeamSchema)
            }
        }
    },
    LIST_OA_DEPRECATED: {
        method: ApiMethod.GET,
        path: '/public/v1/projects/:projectUuid/teams',
        handlerMethod: 'getTeams' as 'getTeams',
        publicApi: true as true,
        description: 'Gets all teams and members for a given project',
        group: Group.Projects,
        zod: {
            params: oaProjectUuidSchema
        },
        responses: {
            200: {
                schema: z.array(oaTeamWithMembersSchema),
                description: 'Teams list'
            }
        }
    },
    POST: {
        method: ApiMethod.POST,
        path: '/workspaces/:workspaceName/projects/:projectUuid/teams',
        handlerMethod: 'postTeam' as 'postTeam',
        zod: {
            params: projectParamsSchema,
            body: teamPostBody
        },
        response: {
            200: {
                description: 'Team created',
                schema: teamSchema
            }
        }
    },
    POST_OA: {
        method: ApiMethod.POST,
        path: '/public/v1/workspaces/:workspaceName/projects/:projectUuid/teams',
        handlerMethod: 'postTeam' as 'postTeam',
        zod: {
            params: oaProjectParamsSchema,
            body: oaTeamPostBody
        },
        response: {
            200: {
                description: 'Team created',
                schema: oaTeamSchema
            }
        }
    },
    PATCH: {
        method: ApiMethod.PATCH,
        path: '/workspaces/:workspaceName/projects/:projectUuid/teams/:teamUuid',
        handlerMethod: 'patchTeam' as 'patchTeam',
        zod: {
            params: teamParamsSchema,
            body: teamPutBody
        },
        response: {
            200: {
                description: 'Team updated',
                schema: teamSchema
            }
        }
    }
};

export const API_TEAM_USER = {
    POST: {
        method: ApiMethod.POST,
        path: '/workspaces/:workspaceName/projects/:projectUuid/teams/:teamUuid/users',
        handlerMethod: 'postTeamUsers' as 'postTeamUsers',
        zod: {
            params: teamParamsSchema,
            body: teamUsersPostBody
        }
    },
    POST_OA: {
        method: ApiMethod.POST,
        path: '/public/v1/workspaces/:workspaceName/projects/:projectUuid/teams/:teamUuid/users',
        handlerMethod: 'postTeamUsers' as 'postTeamUsers',
        zod: {
            params: oaTeamParamsSchema,
            body: oaTeamUsersPostBody
        }
    },

    PATCH: {
        method: ApiMethod.PATCH,
        path: '/workspaces/:workspaceName/projects/:projectUuid/teams/:teamUuid/users/:userUuid',
        handlerMethod: 'patchTeamUser' as 'patchTeamUser',
        zod: {
            params: teamUserParamsSchema,
            body: teamUserPatchBody
        }
    },
    DELETE: {
        method: ApiMethod.DELETE,
        path: '/workspaces/:workspaceName/projects/:projectUuid/teams/:teamUuid/users/:userUuid',
        handlerMethod: 'deleteTeamUser' as 'deleteTeamUser',
        zod: {
            params: teamUserParamsSchema
        }
    },
    DELETE_OA_DEPRECATED: {
        method: ApiMethod.DELETE,
        path: '/public/v1/projects/:projectUuid/users/:userUuid',
        handlerMethod: 'deleteTeamUser' as 'deleteTeamUser',
        publicApi: true,
        description: 'Removes a member from project',
        group: Group.Projects,
        zod: {
            params: oaProjectUuidAndUserUuidSchema
        },
        responses: {
            204: {
                description: 'User successfully removed from project'
            }
        }
    }
};
