import { EntitiesState, Entity } from '@he-novation/config/types/entity.types';
import update from 'immutability-helper';
import { v4 } from 'uuid';

import { asyncActionSuccess } from '$helpers/asyncAction';
import {
    ENTITIES_FETCH,
    ENTITY_SET_PICTURE_ASSET,
    WS_ENTITIES_CREATE,
    WS_ENTITIES_DELETE,
    WS_ENTITIES_UPDATE
} from '$redux/content/entities/entitiesActions';
import { ActionType } from '$redux/store';

export const entitiesInitialState: EntitiesState = { isLoaded: false, list: [] };

export default (state = entitiesInitialState, action: ActionType) => {
    switch (action.type) {
        case asyncActionSuccess(ENTITIES_FETCH):
            return update(state, {
                isLoaded: { $set: true },
                list: { $set: action.entities as Entity[] }
            });

        case ENTITY_SET_PICTURE_ASSET: {
            const i = state.list.findIndex(({ uuid }) => uuid === action.entityUuid);
            if (i === -1) return state;
            return update(state, {
                list: {
                    [i]: {
                        pictureAsset: {
                            $set: {
                                url: action.url as string,
                                urlExpires: null,
                                uuid: `tmp-${v4()}`,
                                key: action.key as string
                            }
                        }
                    }
                }
            });
        }

        case WS_ENTITIES_CREATE:
            return update(state, {
                list: { $push: [action.data as Entity] }
            });

        case WS_ENTITIES_UPDATE: {
            const i = state.list.findIndex(({ uuid }) => uuid === (action.data as Entity).uuid);
            if (i === -1) return state;

            return update(state, {
                list: {
                    [i]: { $merge: action.data as Entity }
                }
            });
        }

        case WS_ENTITIES_DELETE: {
            const i = state.list.findIndex(
                ({ uuid }) => uuid === (action.data as { uuid: string }).uuid
            );
            if (i === -1) return state;

            return update(state, {
                list: { $splice: [[i, 1]] }
            });
        }
    }

    return state;
};
