import { projectsLink } from '@he-novation/config/paths/herawFrontUris';
import { TeamUpdateBody } from '@he-novation/config/types/payloads/team.payload';
import { TaskCreateOrUpdateResponse } from '@he-novation/config/types/responses/task.responses';
import { Team } from '@he-novation/config/types/team.types';
import {
    WSProjectTaskAttachmentUpload,
    WSProjectTaskCreate
} from '@he-novation/config/types/websockets/project.ws.types';
import {
    asyncTeamUpdate,
    deleteTeam as _deleteTeam,
    fetchProject as _fetchProject,
    fetchProjectTasks as _fetchProjectTasks
} from '@he-novation/front-shared/async/project.async';
import {
    asyncTaskDelete,
    asyncTaskPost,
    asyncTasksFetch,
    asyncTaskUpdate,
    updateMultipleTaskStatusAndOrdering as _updateMultipleTaskStatusAndOrdering
} from '@he-novation/front-shared/async/task.async';
import { Dispatch } from 'redux';
import { set as setRoute } from '../../route/routeActions';

import { WS_PREFIX_IN } from '$constants/webSocket.constants';
import { asyncActionSuccess } from '$helpers/asyncAction';
import { browserSessionUuid } from '$helpers/browserSession';

export const PROJECTS = 'PROJECTS';
export const CREATE_PROJECT = `${PROJECTS}/CREATE_PROJECT`;
export const ARCHIVE_PROJECT = `${PROJECTS}/ARCHIVE_PROJECT`;
export const RESTORE_PROJECT = `${PROJECTS}/RESTORE_PROJECT`;
export const CREATE_TASK = `${PROJECTS}/CREATE_TASK`;
export const DELETE_TASK = `${PROJECTS}/DELETE_TASK`;
export const DELETE_TEAM = `${PROJECTS}/DELETE_TEAM`;
export const UPDATE_TEAM = `${PROJECTS}/UPDATE_TEAM`;
export const SET_SELECTED_TEAM = `${PROJECTS}/SET_SELECTED_TEAM`;
export const SET_LAST_PROJECT_UUID = `${PROJECTS}/SET_LAST_PROJECT_UUID`;
export const FETCH_PROJECT = `${PROJECTS}/FETCH_PROJECT`;
export const FETCH_PROJECT_TASKS = `${PROJECTS}/FETCH_PROJECT_TASKS`;
export const FETCH_PLANNING_TASKS = `${PROJECTS}/FETCH_PLANNING_TASKS`;
export const FETCH_PROJECTS = `${PROJECTS}/FETCH_PROJECTS`;
export const FETCH_PROJECT_ACTIVITY = `${PROJECTS}/FETCH_PROJECT_ACTIVITY`;
export const FETCH_PROJECT_LAST_ACTIVITY = `${PROJECTS}/FETCH_PROJECT_LAST_ACTIVITY`;
export const FETCH_PROJECT_COMPANIES = `${PROJECTS}/FETCH_PROJECT_COMPANIES`;
export const FETCH_TASK_ASSETS = `${PROJECTS}/FETCH_TASK_ASSETS`;
export const TEAM_INVITE_MEMBERS = `${PROJECTS}/TEAM_INVITE_MEMBERS`;
export const FETCH_CURRENT_USER_PROJECTS = `${PROJECTS}/FETCH_CURRENT_USER_PROJECTS`;
export const UPDATE_CURRENT_PROJECT = `${PROJECTS}/UPDATE_CURRENT_PROJECT`;
export const UPDATE_TASK = `${PROJECTS}/UPDATE_TASK`;
export const UPDATE_TASK_STATUS_ORDER = `${PROJECTS}/UPDATE_TASK_STATUS_ORDER`;
export const UPDATE_USER_METADATA = `${PROJECTS}/UPDATE_USER_METADATA`;
export const TASK_ATTACHMENT_UPLOAD = `${PROJECTS}/TASK_ATTACHMENT_UPLOAD`;

export const WS_ARCHIVE_PROJECT = `${WS_PREFIX_IN}${ARCHIVE_PROJECT}`;
export const WS_RESTORE_PROJECT = `${WS_PREFIX_IN}${RESTORE_PROJECT}`;
export const WS_UPDATE_CURRENT_PROJECT = `${WS_PREFIX_IN}${UPDATE_CURRENT_PROJECT}`;
export const WS_TASK_CREATE = `${WS_PREFIX_IN}${CREATE_TASK}`;
export const WS_TASK_UPDATE = `${WS_PREFIX_IN}${UPDATE_TASK}`;
export const WS_TASK_DELETE = `${WS_PREFIX_IN}${DELETE_TASK}`;
export const WS_TASK_ATTACHMENT_UPLOAD = `${WS_PREFIX_IN}${TASK_ATTACHMENT_UPLOAD}`;

export const createTask =
    (
        workspaceName: string,
        projectUuid: string,
        body,
        cb: (payload: TaskCreateOrUpdateResponse) => void,
        onError?: (error: unknown) => void
    ) =>
    async (dispatch) => {
        dispatch({ type: CREATE_TASK });
        try {
            const r = await asyncTaskPost(workspaceName, projectUuid, body);
            cb?.(r);
        } catch (e) {
            onError?.(e);
        }
    };

export const deleteTask =
    (workspaceName: string, projectUuid: string, taskUuid: string) => async (dispatch) => {
        dispatch({ type: DELETE_TASK });
        await asyncTaskDelete(workspaceName, projectUuid, taskUuid);
    };

export const deleteTeam =
    (workspaceName: string, teamUuid: string, projectUuid: string, cb) => async (dispatch) => {
        dispatch({ type: DELETE_TEAM });
        await _deleteTeam(workspaceName, teamUuid, projectUuid);
        if (typeof cb === 'function') cb();
    };

export const updateTeam =
    (
        workspaceName: string,
        projectUuid: string,
        teamUuid: string,
        body: TeamUpdateBody,
        cb?: (team: Team) => void
    ) =>
    async (dispatch: Dispatch) => {
        dispatch({ type: UPDATE_TEAM });
        const team = await asyncTeamUpdate(workspaceName, projectUuid, teamUuid, body);
        dispatch({ type: asyncActionSuccess(UPDATE_TEAM), team });
        cb?.(team);
    };

export const fetchProject = (workspaceName: string, uuid: string) => async (dispatch) => {
    dispatch({ type: FETCH_PROJECT });
    try {
        const response = await _fetchProject(workspaceName, uuid);
        dispatch({
            type: asyncActionSuccess(FETCH_PROJECT),
            project: {
                ...response
            }
        });
    } catch (e) {
        if (e.code === 404) dispatch(setRoute(projectsLink(), false, true));
    }
};

export const fetchProjectTasks =
    (workspaceName: string, projectUuid: string) => async (dispatch) => {
        dispatch({ type: FETCH_PROJECT_TASKS });
        const tasks = await _fetchProjectTasks(workspaceName, projectUuid);
        dispatch({ type: asyncActionSuccess(FETCH_PROJECT_TASKS), tasks });
    };

export const fetchPlanningTasks = (startDate, endDate) => async (dispatch) => {
    dispatch({ type: FETCH_PLANNING_TASKS });
    const planningTasks = await asyncTasksFetch({ startDate, endDate });

    dispatch({ type: asyncActionSuccess(FETCH_PLANNING_TASKS), planningTasks });
};

export const updateTask =
    (
        workspaceName: string,
        projectUuid: string,
        taskUuid: string,
        data: any,
        cb?: (payload: TaskCreateOrUpdateResponse) => void,
        onError?: (e: unknown) => void
    ) =>
    async (dispatch) => {
        dispatch({ type: UPDATE_TASK });
        try {
            const payload = await asyncTaskUpdate(workspaceName, projectUuid, {
                taskUuid,
                ...data
            });
            if (typeof cb === 'function') cb(payload);
        } catch (e) {
            onError?.(e);
        }
    };

export const updateMultipleTaskStatusAndOrdering =
    (browserSessionUuid, changes) => async (dispatch) => {
        dispatch({ type: UPDATE_TASK_STATUS_ORDER });
        const response = await _updateMultipleTaskStatusAndOrdering(browserSessionUuid, changes);
        dispatch({ type: asyncActionSuccess(UPDATE_TASK_STATUS_ORDER), changes: response });
    };

export const sioProjectTasksSocketAction = {
    sioTaskCreate: (payload: WSProjectTaskCreate) => ({
        type: WS_TASK_CREATE,
        task: payload.task
    }),
    sioTaskUpdate: ({ task, newOrderingAndStatusByUuid, browserSessionUuid }) => ({
        type: WS_TASK_UPDATE,
        task,
        newOrderingAndStatusByUuid,
        browserSessionUuid
    }),
    sioTaskDelete: (taskUuid) => ({
        type: WS_TASK_DELETE,
        taskUuid
    }),
    sioTaskAttachmentUpload: (payload: WSProjectTaskAttachmentUpload) => ({
        type: WS_TASK_ATTACHMENT_UPLOAD,
        ...{
            taskUuid: payload.taskUuid,
            attachment: payload.attachment
        }
    })
};

export const sioProjectSocketAction = {
    sioProjectUpdate: (project) => ({
        type: WS_UPDATE_CURRENT_PROJECT,
        project
    })
};

export const PROJECT_SET_NOTIFICATION_SETTINGS = `${PROJECTS}/SET_NOTIFICATION_SETTINGS`;

export function projectSetNotificationSettings() {
    return {
        type: PROJECT_SET_NOTIFICATION_SETTINGS
    };
}
