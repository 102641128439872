import { z } from 'zod';
import { isoStringToDate } from '@he-novation/utils/datetime';
import { baseUploadPayload } from './asset.payload';
import { sanitizedString } from '@he-novation/utils/zod.utils';
import { TaskStatus } from '../db/enums';

export const specificTaskSchema = z.object({
    taskUuid: z.string().uuid()
});

export type SpecificTaskSchema = z.infer<typeof specificTaskSchema>;

export const taskCreateBody = z.object({
    assignees: z.array(z.string()).nullable().optional(),
    attachments: z.array(baseUploadPayload),
    teamUuid: z.string().uuid(),
    status: z.nativeEnum(TaskStatus).optional(),
    description: sanitizedString({ html: true }),
    estimatedEndDate: z
        .string()
        .nullable()
        .optional()
        .transform((value) => {
            if (value === 'null') return null;
            if (value) return isoStringToDate(value);
            return undefined;
        })
});

export type TaskCreateBody = z.infer<typeof taskCreateBody>;

// is multipart string
export const taskUpdateBody = z.object({
    assignees: z
        .string()
        .nullable()
        .optional()
        .transform((value) => {
            if (value === 'null') return null;
            if (value === undefined) return undefined;
            return value ? value.split(',') : [];
        }),
    attachments: z.array(baseUploadPayload),
    teamUuid: z.string().uuid().optional(),
    status: z.nativeEnum(TaskStatus).optional(),
    description: sanitizedString({ html: true, optional: true }),
    estimatedEndDate: z
        .string()
        .nullable()
        .optional()
        .transform((value) => {
            if (value === 'null') return null;
            if (value) return isoStringToDate(value);
            return undefined;
        })
});

export type TaskUpdateBody = z.infer<typeof taskUpdateBody>;

export const taskParamsSchema = z.object({
    workspaceName: sanitizedString(),
    projectUuid: z.string().uuid(),
    taskUuid: z.string().uuid()
});
