import { z } from 'zod';
import { oaUserFileSchema } from './user.open-api.types';
import { oaClientLabelSchema } from './client.open-api.types';
import { sanitizedString } from '@he-novation/utils/zod.utils';

export const oaCastSchema = z.object({
    uid: z.string(),
    name: sanitizedString({ min: 1, max: 255 }),
    download: z.boolean(),
    downloadProxies: z.boolean(),
    comment: z.boolean(),
    views: z.number(),
    maxViews: z.number().optional().nullable(),
    created: z.date(),
    password: sanitizedString({ nullable: true, optional: true }),
    expires: z.date().optional().nullable(),
    expired: z.boolean(),
    backgroundColor: z.string().optional().nullable(),
    textColor: z.string().optional().nullable(),
    user: z.object({
        uuid: z.string(),
        email: z.string()
    }),
    bgUserFile: oaUserFileSchema.omit({ isPrivate: true }).optional().nullable(),
    logoUserFile: oaUserFileSchema.omit({ isPrivate: true }).optional().nullable(),
    project: z
        .object({
            uuid: z.string(),
            name: z.string()
        })
        .optional()
        .nullable(),
    labels: z.array(oaClientLabelSchema).optional().nullable(),
    client: z.object({
        uuid: z.string(),
        name: z.string()
    })
});
export type OACast = z.infer<typeof oaCastSchema>;

export const oaFolderCastSchema = oaCastSchema.extend({
    folderCastTriggerUid: z.string(),
    castFolder: z
        .object({
            uuid: z.string(),
            name: z.string()
        })
        .optional()
});

export type OAFolderCast = z.infer<typeof oaFolderCastSchema>;
export const oaFileCastSchema = oaCastSchema.extend({
    castFileUuid: z.string(),
    castFolder: z
        .object({
            uuid: z.string(),
            name: z.string()
        })
        .optional()
});
export type OAFileCast = z.infer<typeof oaFileCastSchema>;

/**
 * @deprecated use castShareBody instead
 */
export const oaCastShareBody = z.object({
    emails: z.array(z.string().email()),
    cast: z.object({
        name: sanitizedString(),
        uid: z.string().uuid()
    }),
    message: sanitizedString({ optional: true, nullable: true })
});

/**
 * @deprecated
 */
export type OACastShareBody = z.infer<typeof oaCastShareBody>;
