import { z } from 'zod';
import { ROLES } from '../../constants/projects.constants';
import { sanitizedString } from '@he-novation/utils/zod.utils';
import { projects_status } from '../db/enums';

export const projectSpecificSchema = z.object({
    projectUuid: z.string().uuid()
});

export type ProjectSpecificSchema = z.infer<typeof projectSpecificSchema>;

export const projectCreateBody = z.object({
    name: sanitizedString(),
    startDate: z.coerce.date().optional().nullable(),
    endDate: z.coerce.date().optional().nullable(),
    companyName: z.string().optional().nullable(),
    disableCasts: z.boolean().optional(),
    backgroundColor: z.string().optional().nullable(),
    logoUserFileUid: z.string().optional().nullable(),
    backgroundUserFileUid: z.string().optional().nullable()
});

export type ProjectCreateBody = z.infer<typeof projectCreateBody>;
export const projectUpdateBody = projectCreateBody.partial().extend({
    status: z.nativeEnum(projects_status).optional()
});
export type ProjectUpdateBody = z.infer<typeof projectUpdateBody>;

export const projectUuidAndUserUuidSchema = z.object({
    projectUuid: z.string().uuid(),
    userUuid: z.string().uuid()
});

export type ProjectUuidAndUserUuidSchema = z.infer<typeof projectUuidAndUserUuidSchema>;

export const projectUuidAndClientNameSchema = z.object({
    projectUuid: z.string().uuid(),
    clientName: z.string()
});

export type ProjectUuidAndClientNameSchema = z.infer<typeof projectUuidAndClientNameSchema>;

export const projectUpdateMemberBody = z.object({
    teamUuid: z.string().uuid().optional(),
    role: z.nativeEnum(ROLES).optional(),
    download: z.boolean().optional()
});

export type ProjectUpdateMemberBody = z.infer<typeof projectUpdateMemberBody>;

export const folderConvertToProjectBody = z.object({
    folderUuid: z.string().uuid(),
    companyName: z.string().optional().nullable()
});

export type FolderConvertToProjectBody = z.infer<typeof folderConvertToProjectBody>;

export const projectParamsSchema = z.object({
    workspaceName: sanitizedString(),
    projectUuid: z.string().uuid()
});
