import { buildApiCall } from '@he-novation/config/apis/api-fetch';
import { API_PLUGIN_EXPORT } from '@he-novation/config/apis/plugin-api';
import {
    filePaths,
    pathWithParams,
    publicApiV1Paths
} from '@he-novation/config/paths/herawApiPaths';
import type { FileFull, VersionAssetRecap } from '@he-novation/config/types/file.types';
import { FileVotes } from '@he-novation/config/types/file.types';
import {
    FileAssetSpecificParams,
    FileCopyBody,
    FileCreateBody,
    FileFinalVoteBody,
    FileMoveParams,
    FilePasswordQuery,
    FileSpecificParams,
    FileStatusUpdateBody,
    FileUpdateBody
} from '@he-novation/config/types/payloads/file.payload';
import { PluginExportFile } from '@he-novation/config/types/plugin.types';
import { FileCreateResponse } from '@he-novation/config/types/responses/file.responses';
import type { Subtitle } from '@he-novation/config/types/subtitle.types';
import { apiFetch } from './apiFetch';

export const fetchFile = ({
    uuid,
    version,
    public_password,
    isFromCast
}: {
    uuid: string;
    version: number;
    public_password?: string | null;
    isFromCast?: boolean;
}): Promise<FileFull> =>
    apiFetch(
        pathWithParams(
            filePaths.versionSpecific,
            { fileUuid: uuid, fileVersion: version },
            public_password || isFromCast
                ? { p: public_password, isFromCast: isFromCast ? '1' : '0' }
                : undefined
        ),
        {
            method: 'GET'
        }
    ).then((file) => ({
        ...file,
        created: file.created ? new Date(file.created) : undefined,
        updated: file.updated ? new Date(file.updated) : undefined
    }));

export const asyncFileVersionsFetch = (fileUuid: string): Promise<VersionAssetRecap[]> =>
    apiFetch(filePaths.versions, {
        method: 'GET',
        params: {
            fileUuid
        }
    });

export const fileLinkFetch = ({
    uuid,
    version,
    quality,
    password
}: {
    uuid: string;
    version: number;
    quality: string;
    password?: string;
}) =>
    apiFetch(filePaths.assetQuality, {
        method: 'GET',
        params: {
            fileUuid: uuid,
            fileVersion: version,
            quality
        } satisfies FileAssetSpecificParams,
        query: { p: password } satisfies FilePasswordQuery
    }).then(({ url }) => url);

export function openLink(url: string) {
    const a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

export function download(url: string, name?: string) {
    const a = document.createElement('a');
    a.href = url;
    a.download = name || (url.split('/').pop() as string);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

export const fileDownload = async (
    uuid: string,
    version: number,
    quality: string,
    password?: string,
    name?: string
) => {
    const link = await fileLinkFetch({ uuid, version, quality, password });
    download(link, name);
};

export const deleteVersion = ({ uuid, version }) =>
    apiFetch(pathWithParams(filePaths.versionSpecific, { fileUuid: uuid, fileVersion: version }), {
        method: 'DELETE'
    });

export const restoreVersion = ({ uuid, version }) =>
    apiFetch(pathWithParams(filePaths.versionSpecific, { fileUuid: uuid, fileVersion: version }), {
        method: 'PATCH'
    });

export const fetchFileVotes = (fileUuid: string, fileVersion: number): Promise<FileVotes> =>
    apiFetch(filePaths.final, {
        method: 'GET',
        params: { fileUuid, fileVersion }
    }).then((r: FileVotes) => ({
        ...r,
        voters: r.voters.map((v) => ({ ...v, created: new Date(v.created) }))
    }));

export const fetchSubtitles = (
    fileUuid: string,
    fileVersion: number | string,
    password?: string | null
): Promise<Subtitle[]> =>
    apiFetch(filePaths.subtitles, {
        params: { fileUuid, fileVersion },
        query: { p: password },
        method: 'GET'
    });

export const asyncFileUpdate = (fileUuid: string, fileVersion: number, body: FileUpdateBody) =>
    apiFetch(pathWithParams(filePaths.versionSpecific, { fileUuid, fileVersion }), {
        method: 'PUT',
        body
    });

export const fileEncryptedAccessTokenRequest = (request_token: string) =>
    apiFetch('/proxy/get/access/token', {
        method: 'POST',
        body: JSON.stringify({
            request_token,
            delay: 0
        })
    });

export const fileCopy = (fileUuid: string, fileVersion: number, folderUuid: string) =>
    apiFetch(filePaths.copy, {
        method: 'POST',
        params: {
            fileUuid,
            fileVersion
        } satisfies FileSpecificParams,
        body: {
            folderUuid
        } satisfies FileCopyBody
    });

export const fileBulkDelete = (folderUuid: string, fileUuids: string[]) =>
    apiFetch(filePaths.bulkDelete, {
        method: 'POST',
        body: { parentUuid: folderUuid, uuids: fileUuids }
    });

export const fileMove = (fileUuid: string, folderUuid: string) =>
    apiFetch(filePaths.move, {
        params: { fileUuid, folderUuid } satisfies FileMoveParams,
        method: 'PUT'
    });

export const asyncFileRestore = (fileUuid: string, fileVersion: number) =>
    apiFetch(filePaths.restore, {
        method: 'POST',
        params: { fileUuid, fileVersion }
    });

export const fileVoteFinal = (
    fileUuid: string,
    fileVersion: number,
    body: FileFinalVoteBody
): Promise<FileVotes> =>
    apiFetch(filePaths.final, {
        method: 'PUT',
        params: { fileUuid, fileVersion },
        body
    }).then((r: FileVotes) => ({
        ...r,
        voters: r.voters.map((v) => ({ ...v, created: new Date(v.created) }))
    }));

export const fileStatusUpdate = (
    fileUuid: string,
    fileVersion: number,
    body: FileStatusUpdateBody
) =>
    apiFetch(filePaths.status, {
        method: 'PUT',
        params: { fileUuid, fileVersion },
        body
    });

export const exportFiles = (
    folderUuid: string,
    items: PluginExportFile[],
    plugin: 'brightcove' | 'youtube' | 'vimeo'
) =>
    buildApiCall(API_PLUGIN_EXPORT.POST)({
        params: { plugin },
        body: { items, folderUuid }
    });

export const asyncConvertFileToVersion = (
    targetFileUuid: string,
    targetFileVersion: number,
    sourceFileUuid: string
) =>
    apiFetch(filePaths.version, {
        method: 'POST',
        params: { fileUuid: targetFileUuid, fileVersion: targetFileVersion },
        body: {
            fileUuid: sourceFileUuid
        }
    });

export const asyncFileEncode = (fileUuid: string, fileVersion: number) =>
    apiFetch(filePaths.encode, {
        method: 'PUT',
        params: { fileUuid, fileVersion }
    });

export function asyncFileCreate(body: FileCreateBody): Promise<FileCreateResponse> {
    return apiFetch(filePaths.single, {
        method: 'POST',
        body
    });
}

export function asyncVersionCreate(
    fileUuid: string,
    body: FileCreateBody
): Promise<FileCreateResponse> {
    return apiFetch(filePaths.versions, {
        method: 'POST',
        params: { fileUuid },
        body
    });
}

export const asyncOpenApiFileFetch = (apiKey: string, fileUuid: string, fileVersion: number) =>
    apiFetch(publicApiV1Paths.file.specificVersion, {
        headers: {
            Authorization: 'Bearer ' + apiKey
        },
        params: { fileUuid, fileVersion }
    });

export const readMagicBytes = async (file: File): Promise<string | null> => {
    try {
        const input = file.slice(0, Math.min(file.size, 1024 * 10));
        const buffer = new Uint8Array(await input.arrayBuffer());
        const data: {
            mimeType: string | null;
        } = await apiFetch(
            filePaths.mimeType,
            {
                headers: {
                    'content-type': 'application/octet-stream'
                },
                method: 'POST',
                body: buffer,
                query: { extension: file.name.split('.').pop(), mimeType: file.type }
            },
            false
        );
        return data.mimeType || null;
    } catch (e) {
        return null;
    }
};
