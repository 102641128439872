import { buildApiCall } from '@he-novation/config/apis/api-fetch';
import { API_ASSET, API_ASSET_UPLOAD, API_BUCKET } from '@he-novation/config/apis/asset-api';
import type { Asset } from '@he-novation/config/types/asset.types';
import { Bucket } from '@he-novation/config/types/bucket.types';
import {
    AssetStatusBody,
    AssetUploadAbortBody,
    AssetUploadCompleteBody,
    MigrateFolderAssetsBody
} from '@he-novation/config/types/payloads/asset.payload';

export const asyncAssetFetch = (workspaceName: string, assetUuid: string) =>
    buildApiCall(API_ASSET.GET)({
        params: { workspaceName, assetUuid }
    });

export const asyncCompleteMultipartUpload = (
    workspaceName: string,
    assetUuid: string,
    body: AssetUploadCompleteBody
): Promise<Asset> =>
    buildApiCall(API_ASSET_UPLOAD.PATCH)({
        params: { workspaceName, assetUuid },
        body
    });

export const asyncAbortMultipartUpload = (
    workspaceName: string,
    assetUuid: string,
    body: AssetUploadAbortBody
) =>
    buildApiCall(API_ASSET_UPLOAD.DELETE)({
        params: { workspaceName, assetUuid },
        body
    });

export const asyncAssetStatusUpdate = (
    workspaceName: string,
    assetUuid: string,
    body: AssetStatusBody
) =>
    buildApiCall(API_ASSET.STATUS_PUT)({
        params: { workspaceName, assetUuid },
        body
    });

export async function getSignedUrl(workspaceName: string, assetUuid: string): Promise<string> {
    const { url } = await buildApiCall(API_ASSET.SIGNED_GET)({
        params: { workspaceName, assetUuid }
    });
    return url;
}

export async function asyncBucketsFetch(workspaceName: string): Promise<Bucket[]> {
    return buildApiCall(API_BUCKET.GET)({
        params: {
            workspaceName
        }
    });
}

export async function asyncMigrateFolderAssets(
    workspaceName: string,
    body: MigrateFolderAssetsBody
) {
    return buildApiCall(API_ASSET.MIGRATE_FOLDER_ASSETS_PUT)({
        params: { workspaceName },
        body
    });
}
