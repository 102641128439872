import { Group } from '../paths/herawApiPathGroups';
import { ApiMethod } from '../types/api.types';
import { oaUserSchema } from '../types/open-api/user.open-api.types';
import {
    auth2FAUpdatePayload,
    authLoginPayload,
    authPasswordResetPayload,
    authPasswordUpdatePayload
} from '../types/payloads/auth.payload';
import { emailSchema } from '../types/payloads/user.payload';
import { workspaceParamsSchema } from '../types/payloads/workspace.payload';

export const API_AUTH_SESSION = {
    GET: {
        method: ApiMethod.GET,
        path: '/auth/session',
        handlerMethod: 'refreshAccessToken' as 'refreshAccessToken',
        passResAndNextToHandler: true as true,
        isPublic: true as true
    },
    POST: {
        method: ApiMethod.POST,
        isPublic: true as true,
        path: '/auth/session',
        handlerMethod: 'login' as 'login',
        passResAndNextToHandler: true as true,
        zod: {
            body: authLoginPayload
        }
    },
    DELETE: {
        method: ApiMethod.DELETE,
        path: '/auth/session',
        passResAndNextToHandler: true as true,
        handlerMethod: 'logout' as 'logout',
        allowNoMfa: true
    }
};

export const API_AUTH_PASSWORD = {
    PUT: {
        method: ApiMethod.PUT,
        path: '/auth/password',
        passResAndNextToHandler: true as true,
        handlerMethod: 'updatePassword' as 'updatePassword',
        zod: {
            body: authPasswordUpdatePayload
        },
        allowNoMfa: true
    },
    RESET: {
        method: ApiMethod.PUT,
        path: '/auth/password/reset',
        passResAndNextToHandler: true as true,
        handlerMethod: 'resetPassword' as 'resetPassword',
        isPublic: true as true,
        zod: {
            body: authPasswordResetPayload
        }
    },
    TOKEN_POST: {
        method: ApiMethod.POST,
        path: '/auth/password/token',
        handlerMethod: 'passwordTokenCreate' as 'passwordTokenCreate',
        isPublic: true as true,
        passResAndNextToHandler: true as true,
        zod: {
            body: emailSchema
        }
    }
};

export const API_AUTH_2FA = {
    PUT: {
        method: ApiMethod.PUT,
        path: '/auth/two-factor',
        handlerMethod: 'update2FA' as 'update2FA',
        passResAndNextToHandler: true as true,
        zod: {
            body: auth2FAUpdatePayload
        },
        allowNoMfa: true
    }
};

export const API_AUTH_USER = {
    // uses a POST request to avoid logging sensitive data in the URL
    //@deprecated should be a client_user route
    GET_POST_OA_DEPRECATED: {
        method: ApiMethod.POST,
        publicApi: 'deprecated' as 'deprecated',
        path: '/public/v1/auth/user',
        handlerMethod: 'getOpenApiUser' as 'getOpenApiUser',
        passResAndNextToHandler: true as true,
        description: 'Fetches user from email and password',
        zod: {
            body: authLoginPayload
        },
        isPublic: true as true,
        group: Group.Users,
        responses: {
            200: {
                description: 'User infos',
                schema: oaUserSchema
            }
        }
    }
};
