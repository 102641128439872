import styles from './SidePanelItemKits.module.css';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { directoryItemKitLink } from '@he-novation/config/paths/herawFrontUris';
import { DELETE_CONFIRM, ITEM_KIT } from '@he-novation/config/paths/modals.constants';
import { ItemKit } from '@he-novation/config/types/item.types';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { Title } from '@he-novation/design-system/components/text/Title/Title';
import { Loader } from '@he-novation/design-system/components/widgets/Loader/Loader';
import { itemKitDelete } from '@he-novation/front-shared/async/item.async';
import { useAtomValue } from 'jotai';
import { SidePanelList } from '../../SidePanel/SidePanelList/SidePanelList';

import { workspaceNameAtom } from '$atoms/workspace-atoms';
import combineSelectors from '$helpers/combineSelectors';
import { useModal } from '$hooks/useModal';
import { useTranslate } from '$hooks/useTranslate';
import { kitsSelector } from '$redux/content/items/itemsSelectors';
import { canCreateItemSelector } from '$redux/user/userSelectors';

export function SidePanelItemKits() {
    const { t } = useTranslate();

    const { openModal, closeModal } = useModal();

    const workspaceName = useAtomValue(workspaceNameAtom);

    const { kits, canCreateItem } = useSelector(
        combineSelectors(kitsSelector, canCreateItemSelector)
    );

    const openItemKitModal = useCallback(() => openModal(ITEM_KIT), []);
    const deleteItemKit = useCallback(
        (uuid: string) =>
            openModal(DELETE_CONFIRM, {
                onDelete: async () => {
                    await itemKitDelete(workspaceName, uuid);
                    closeModal();
                },
                message: t('common.Are you sure you want to delete this?'),
                title: t('common.Delete'),
                cancel: t('common.cancel')
            }),
        []
    );

    if (!kits.isLoaded) {
        return <Loader />;
    }

    return (
        <div className={styles.itemKitsSidePanel}>
            <Title tag="h2" type="tab" className={styles.title} icon="shapes">
                {t('item.Item kits')}
            </Title>

            <div className={styles.content}>
                <SidePanelList
                    className={styles.list}
                    editItem={({ uuid, name }) => openModal(ITEM_KIT, { uuid, name })}
                    deleteItem={({ uuid }) => deleteItemKit(uuid)}
                    items={kits.list.map(({ name, items, uuid }: ItemKit) => ({
                        name,
                        count: items.length,
                        href: directoryItemKitLink(uuid),
                        uuid
                    }))}
                />

                <Button
                    className={styles.addButton}
                    icon={['shapes', 'plus']}
                    tone={ButtonTone.Outlined}
                    onClick={openItemKitModal}
                    disabled={!canCreateItem}
                >
                    {t('item.New item kit')}
                </Button>
            </div>
        </div>
    );
}
