import { buildApiCall } from '@he-novation/config/apis/api-fetch';
import {
    EntityCreateBody,
    EntityUpdateBody
} from '@he-novation/config/types/payloads/entity.payload';
import {
    ENTITY_DELETE,
    ENTITY_LIST,
    ENTITY_PICTURE_UPLOAD_LINK_POST,
    ENTITY_POST,
    ENTITY_PUT
} from '../../config/apis/entity-api';
import { asyncAssetStatusUpdate } from './asset.async';

export const asyncEntitiesFetch = (workspaceName: string) =>
    buildApiCall(ENTITY_LIST)({
        params: { workspaceName }
    });

export const asyncEntityCreate = (workspaceName: string, body: EntityCreateBody) =>
    buildApiCall(ENTITY_POST)({
        params: { workspaceName },
        body
    });

export const asyncEntityDelete = (workspaceName: string, entityUuid: string) =>
    buildApiCall(ENTITY_DELETE)({
        params: {
            workspaceName,
            entityUuid
        }
    });

export const asyncEntityUpdate = (
    workspaceName: string,
    entityUuid: string,
    body: EntityUpdateBody
) =>
    buildApiCall(ENTITY_PUT)({
        params: {
            workspaceName,
            entityUuid
        },
        body
    });

export const asyncEntityPictureUploadLink = async (
    workspaceName: string,
    entityUuid: string,
    blob: Blob
) => {
    const { link, headers, assetUuid } = await buildApiCall(ENTITY_PICTURE_UPLOAD_LINK_POST)({
        params: {
            workspaceName,
            entityUuid
        },
        body: {
            size: blob.size,
            contentType: blob.type
        }
    });
    await fetch(link, {
        method: 'PUT',
        headers: {
            'Content-Type': blob.type,
            ...headers
        },
        body: blob
    });
    return await asyncAssetStatusUpdate(workspaceName, assetUuid, {
        status: 'READY',
        signUrl: true
    });
};
