import { userFileSchema } from './user.types';
import { baseClientLabelSchema } from './clientLabel.types';
import { contentFileSchema, FileEnum } from './file.types';
import { Asset, assetSchema } from './asset.types';
import { z } from 'zod';
import { sanitizedString } from '@he-novation/utils/zod.utils';
import { castBreadcrumbSchema } from './payloads/cast.payload';

export const castSchema = z.object({
    uid: z.string(),
    name: sanitizedString({
        min: 1,
        max: 255
    }),
    download: z.boolean(),
    downloadProxies: z.boolean(),
    comment: z.boolean(),
    views: z.number(),
    maxViews: z.number().optional().nullable(),
    created: z.date(),
    password: sanitizedString({ nullable: true, optional: true }),
    expires: z.date().optional().nullable(),
    expired: z.boolean(),
    backgroundColor: z.string().optional().nullable(),
    textColor: z.string().optional().nullable(),
    user: z.object({
        uuid: z.string(),
        email: z.string()
    }),
    bgUserFile: userFileSchema.omit({ isPrivate: true }).optional().nullable(),
    logoUserFile: userFileSchema.omit({ isPrivate: true }).optional().nullable(),
    project: z
        .object({
            uuid: z.string(),
            name: z.string()
        })
        .optional()
        .nullable(),
    labels: z.array(baseClientLabelSchema).optional().nullable(),
    client: z.object({
        uuid: z.string(),
        name: z.string()
    })
});
export type Cast = z.infer<typeof castSchema>;

export const folderCastSchema = castSchema.extend({
    folderCastTriggerUid: z.string(),
    castFolder: z
        .object({
            uuid: z.string(),
            name: z.string()
        })
        .optional()
});
export type FolderCast = z.infer<typeof folderCastSchema>;

export const fileCastSchema = castSchema.extend({
    castFileUuid: z.string(),
    castFolder: z
        .object({
            uuid: z.string(),
            name: z.string()
        })
        .optional()
});
export type FileCast = z.infer<typeof fileCastSchema>;

export const castFolderChildFileSchema = z.object({
    type: z.literal('file'),
    uuid: z.string().uuid(),
    folderUuid: z.string().uuid(),
    name: z.string(),
    created: z.date()
});
export type CastFolderChildFile = z.infer<typeof castFolderChildFileSchema>;

export const baseCastFolderChildFolderSchema = z.object({
    type: z.literal('folder'),
    uuid: z.string().uuid(),
    name: z.string()
});
export type CastFolderChildFolder = z.infer<typeof baseCastFolderChildFolderSchema> & {
    content: (CastFolderChildFolder | CastFolderChildFile)[];
};

export const castFolderChildFolderSchema: z.ZodType<CastFolderChildFolder> =
    baseCastFolderChildFolderSchema.extend({
        content: z.lazy(() => castFolderChildFolderSchema.or(castFolderChildFileSchema).array())
    });

export const castContentFolderSchema = z.object({
    type: z.literal('folder'),
    uuid: z.string().uuid(),
    name: z.string(),
    created: z.date(),
    user: z.object({
        email: z.string(),
        firstname: z.string().optional(),
        lastname: z.string().optional()
    })
});

export const castContentFileSchema = contentFileSchema.omit({ assets: true }).extend({
    type: z.literal('file'),
    castFileUuid: z.string().uuid(),
    assets: z.array(assetSchema).optional()
});
export type CastContentFile = z.infer<typeof castContentFileSchema>;

export const castContentChildSchema = z.union([castContentFileSchema, castContentFolderSchema]);

export type CastContentChild = CastContentFolder | CastContentFile;

export type CastContentFolder = z.infer<typeof castContentFolderSchema> & {
    content?: CastContentChild[];
};

export const baseCastFolderSchema = z.object({
    uuid: z.string().uuid(),
    name: z.string(),
    created: z.date()
});

export const castFolderSchema = baseCastFolderSchema.extend({
    castFolder: baseCastFolderSchema.optional().nullable()
});

export type CastFolder = z.infer<typeof castFolderSchema>;

export const castCommonSchema = z.object({
    uid: z.string(),
    name: z.string(),
    download: z.boolean(),
    downloadProxies: z.boolean(),
    comment: z.boolean(),
    views: z.number(),
    maxViews: z.number().optional().nullable(),
    created: z.date(),
    password: z.string().optional().nullable(),
    expires: z.date().optional().nullable(),
    expired: z.boolean(),
    backgroundColor: z.string().optional().nullable(),
    textColor: z.string().optional().nullable(),
    user: z.object({
        uuid: z.string(),
        email: z.string()
    }),
    bgUserFile: userFileSchema.omit({ isPrivate: true }).optional().nullable(),
    logoUserFile: userFileSchema.omit({ isPrivate: true }).optional().nullable(),
    project: z
        .object({
            uuid: z.string(),
            name: z.string()
        })
        .optional()
        .nullable(),
    labels: z.array(baseClientLabelSchema).optional().nullable(),
    client: z.object({
        uuid: z.string(),
        name: z.string()
    }),
    content: z.array(castContentChildSchema),
    breadcrumb: z.array(castBreadcrumbSchema),
    castFolder: castFolderSchema.nullable(),
    plugins: z.array(z.string())
});

export type CastCommon = z.infer<typeof castCommonSchema>;

export type PublicCastColors = {
    // these styles are applied to elements without background
    textColor: string;
    textColorFaded: string;

    //these styles are applied to elements with background
    itemBackgroundColor: string;

    logo?: string | null;
    background?: string | null;
    backgroundGradient?: string | null;
};

export type CastFileState = {
    uuid: string;
    name: string;
    type: FileEnum;
    version: number;
    cast: CastCommon;
    source?: string;
    activeAsset: Asset;
    assets: Asset[];
    duration?: number;
};

export const defaultCastStyleSchema = z.object({
    textColor: z.string().optional().nullable(),
    backgroundColor: z.string().optional().nullable(),
    userFileUid: z.string().optional().nullable(),
    logoUserFileUid: z.string().optional().nullable(),
    backgroundFileUid: z.string().optional().nullable()
});

export type DefaultCastStyle = z.infer<typeof defaultCastStyleSchema>;
