import { z } from 'zod';
import { ApiMethod } from '../types/api.types';
import {
    burntSubtitleParamsSchema,
    deprecatedSubtitleParamsSchema,
    subtitleCreateBody,
    subtitleEntryBody,
    subtitleGenerateBody,
    subtitleGeneratedSchema,
    subtitleQuerySchema,
    subtitleParamsSchema,
    subtitleUpdateSchema,
    subtitleUploadBody,
    subtitleUploadUrlBodySchema,
    subtitleEntryParamsSchema
} from '../types/payloads/subtitle.payload';
import { workspaceParamsSchema } from '../types/payloads/workspace.payload';
import { subtitleSchema } from '../types/subtitle.types';
import {
    subtitleUploadResponse,
    subtitleUploadUrlResponse
} from '../types/responses/subtitle.responses';
import { Group } from '../paths/herawApiPathGroups';

export const API_SUBTITLE = {
    GET: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/subtitles/:subtitleUuid',
        handlerMethod: 'getSubtitles' as 'getSubtitles',
        isPublic: true,
        passResAndNextToHandler: true as true,
        zod: {
            params: subtitleParamsSchema,
            query: subtitleQuerySchema
        },
        description: 'Get a subtitle',
        responses: {
            200: {
                description: 'Subtitle output in the format passed in the query',
                schema: z.string(),
                contentTypes: [
                    'text/vtt',
                    'application/x-subrip',
                    'application/octet-stream',
                    'text/plain'
                ]
            }
        }
    },
    GET_OA: {
        method: ApiMethod.GET,
        path: '/public/v1/workspaces/:workspaceName/subtitles/:subtitleUuid',
        handlerMethod: 'getSubtitles' as 'getSubtitles',
        passResAndNextToHandler: true as true,
        zod: {
            params: subtitleParamsSchema,
            query: subtitleQuerySchema
        },
        description: 'Get a subtitle',
        responses: {
            200: {
                description: 'Subtitle output in the format passed in the query',
                schema: z.string(),
                contentTypes: [
                    'text/vtt',
                    'application/x-subrip',
                    'application/octet-stream',
                    'text/plain'
                ]
            }
        },
        publicApi: true,
        group: Group.Subtitles
    },
    GET_OA_DEPRECATED: {
        method: ApiMethod.GET,
        path: '/public/v1/subtitle/:subtitleUuid',
        handlerMethod: 'getSubtitlesDeprecated' as 'getSubtitlesDeprecated',
        passResAndNextToHandler: true as true,
        zod: {
            params: deprecatedSubtitleParamsSchema,
            query: subtitleQuerySchema
        },
        description: 'Get a subtitle',
        responses: {
            200: {
                description: 'Subtitle output in the format passed in the query',
                schema: z.string(),
                contentTypes: [
                    'text/vtt',
                    'application/x-subrip',
                    'application/octet-stream',
                    'text/plain'
                ]
            }
        },
        publicApi: true,
        group: Group.Subtitles
    },
    POST: {
        method: ApiMethod.POST,
        path: '/workspaces/:workspaceName/subtitles',
        handlerMethod: 'createSubtitles' as 'createSubtitles',
        zod: {
            params: workspaceParamsSchema,
            body: subtitleCreateBody
        },
        responses: {
            200: {
                description: 'The requested subtitle uuid',
                schema: subtitleSchema
            }
        }
    },
    POST_OA_DEPRECATED: {
        method: ApiMethod.POST,
        path: '/public/v1/subtitle',
        handlerMethod: 'uploadSubtitle' as 'uploadSubtitle',
        parseMultipart: true,
        zod: {
            body: subtitleUploadBody
        },
        description: `Upload a subtitle file, locale is a free string but the recommended format is 
        {{language_code}_{{country_code}} following the ISO 639-1 and ISO 3166-1 alpha-2 standards.`,
        responses: {
            200: {
                description: 'Subtitle created',
                schema: subtitleUploadResponse
            }
        },
        publicApi: true,
        group: Group.Subtitles
    },
    DELETE: {
        method: ApiMethod.DELETE,
        path: '/workspaces/:workspaceName/subtitles/:subtitleUuid',
        handlerMethod: 'deleteSubtitles' as 'deleteSubtitles',
        zod: {
            params: subtitleParamsSchema
        }
    },
    UPLOAD_POST: {
        method: ApiMethod.POST,
        path: '/workspaces/:workspaceName/subtitle-upload',
        handlerMethod: 'getSubtitleUploadUrl' as 'getSubtitleUploadUrl',
        zod: {
            params: workspaceParamsSchema,
            body: subtitleUploadUrlBodySchema
        },
        responses: {
            200: {
                description: 'Subtitle, asset and upload URL created',
                schema: subtitleUploadUrlResponse
            }
        }
    },
    BURN_POST: {
        path: `/workspace/:workspaceName/subtitles/:subtitleUuid/burn/:fileUuid/:fileVersion`,
        method: ApiMethod.POST,
        handlerMethod: 'burnSubtitles' as 'burnSubtitles',
        zod: {
            params: burntSubtitleParamsSchema
        },
        responses: {
            204: {
                description: 'The requested subtitle burning has started'
            }
        }
    }
};

export const API_SUBTITLE_ENTRY = {
    POST: {
        method: ApiMethod.POST,
        path: '/workspaces/:workspaceName/subtitles/:subtitleUuid/entries',
        handlerMethod: 'createEntry' as 'createEntry',
        zod: {
            params: subtitleParamsSchema,
            body: subtitleEntryBody
        },
        responses: {
            200: {
                description: 'The requested subtitle entry uuid',
                schema: z.object({
                    uuid: z.string().uuid()
                })
            }
        }
    },

    PUT: {
        method: ApiMethod.PUT,
        path: '/workspaces/:workspaceName/subtitles/:subtitleUuid/entries/:entryUuid',
        handlerMethod: 'updateEntry' as 'updateEntry',
        zod: {
            params: subtitleEntryParamsSchema,
            body: subtitleUpdateSchema
        },
        responses: {
            204: {
                description: 'The requested subtitle entry uuid'
            }
        }
    },

    DELETE: {
        method: ApiMethod.DELETE,
        path: '/workspaces/:workspaceName/subtitles/:subtitleUuid/entries/:entryUuid',
        handlerMethod: 'deleteEntry' as 'deleteEntry',
        zod: {
            params: subtitleEntryParamsSchema
        },
        responses: {
            204: {
                description: 'The requested subtitle entry has been deleted'
            }
        }
    }
};

export const API_SUBTITLE_SPOTL = {
    GENERATE_POST: {
        method: ApiMethod.POST,
        path: '/workspaces/:workspaceName/spotl/subtitles',
        handlerMethod: 'generate' as 'generate',
        zod: {
            params: workspaceParamsSchema,
            body: subtitleGenerateBody
        },
        responses: {
            200: {
                description: 'The requested subtitle generation has been initiated',
                schema: z.array(subtitleGeneratedSchema)
            }
        }
    },
    LANGUAGES_GET: {
        method: ApiMethod.GET,
        path: '/workspaces/:workspaceName/spotl/languages',
        handlerMethod: 'getSpotlLanguages' as 'getSpotlLanguages',
        zod: {
            params: workspaceParamsSchema
        }
    }
};
