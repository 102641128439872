import { z } from 'zod';
import { FileStatus } from '../db/enums';
import { baseUploadPayload } from './asset.payload';
import { sanitizedString } from '@he-novation/utils/zod.utils';

export const fileParamsSchema = z.object({
    workspaceName: z.string(),
    fileUuid: z.string().uuid(),
    fileVersion: z.coerce.number().optional()
});
export const fileVersionParamsSchema = z.object({
    workspaceName: z.string(),
    fileUuid: z.string().uuid(),
    fileVersion: z.coerce.number()
});
export const fileUuidSchema = z.object({
    fileUuid: z.string().uuid()
});

export type FileUuidSchema = z.infer<typeof fileUuidSchema>;

export const fileSpecificParams = z.object({
    fileUuid: z.string().uuid(),
    fileVersion: z.coerce.number()
});

export type FileSpecificParams = z.infer<typeof fileSpecificParams>;
export const fileAssetSpecificParams = z.object({
    fileUuid: z.string().uuid(),
    fileVersion: z.coerce.number(),
    quality: sanitizedString({ min: 2 })
});

export type FileAssetSpecificParams = z.infer<typeof fileAssetSpecificParams>;

export const fileUpdateBody = z.object({
    name: sanitizedString({ optional: true }),
    tags: z.array(z.string()).optional(),
    metadata: z.any().optional(),
    final: z.boolean().optional(),
    description: sanitizedString({ optional: true, nullable: true }),
    status: z.nativeEnum(FileStatus).optional()
});

export type FileUpdateBody = z.infer<typeof fileUpdateBody>;

export const fileMoveParams = z.object({
    fileUuid: z.string().uuid(),
    folderUuid: z.string().uuid()
});

export type FileMoveParams = z.infer<typeof fileMoveParams>;

export const filePasswordQuery = z.object({
    p: z.string().optional()
});

export type FilePasswordQuery = z.infer<typeof filePasswordQuery>;

export const fileFinalVoteBody = z.object({
    voteForUserUuid: z.string().uuid().optional().nullable(),
    final: z.boolean()
});

export type FileFinalVoteBody = z.infer<typeof fileFinalVoteBody>;

export const fileStatusUpdateBody = z.object({
    status: z.nativeEnum(FileStatus)
});

export type FileStatusUpdateBody = z.infer<typeof fileStatusUpdateBody>;

export const fileVersionAddBody = z.object({
    fileUuid: z.string().uuid()
});

export type FileVersionAddBody = z.infer<typeof fileVersionAddBody>;

export const fileCreateBody = baseUploadPayload.extend({
    folderUuid: z.string().uuid(),
    version: z.number(),
    uploadGroup: z.string()
});

export type FileCreateBody = z.infer<typeof fileCreateBody>;

export const fileMimeTypeQuery = z.object({
    extension: sanitizedString(),
    mimeType: z.string()
});

export type FileMimeTypeQuery = z.infer<typeof fileMimeTypeQuery>;

export const fileCopyBody = z.object({
    folderUuid: z.string().uuid()
});

export type FileCopyBody = z.infer<typeof fileCopyBody>;

export const fileBulkDeleteBody = z.object({
    parentUuid: z.string().uuid(),
    uuids: z.array(z.string().uuid())
});

export type FileBulkDeleteBody = z.infer<typeof fileBulkDeleteBody>;
