import { buildApiCall } from '@he-novation/config/apis/api-fetch';
import { API_SUBTITLE_SPOTL } from '@he-novation/config/apis/subtitle-api';
import { SubtitleGenerateBody } from '@he-novation/config/types/payloads/subtitle.payload';
import { SpotlLanguage } from '@he-novation/config/types/subtitle.types';
import { apiFetch } from '@he-novation/front-shared/async/apiFetch';

export const asyncSubtitlesGenerate = (workspaceName: string, body: SubtitleGenerateBody) =>
    buildApiCall(API_SUBTITLE_SPOTL.GENERATE_POST)({
        params: {
            workspaceName
        },
        body
    });

export const asyncSubtitlesAvailableLanguagesFetch = (
    workspaceName: string
): Promise<SpotlLanguage[]> =>
    buildApiCall(API_SUBTITLE_SPOTL.LANGUAGES_GET)({
        params: {
            workspaceName
        }
    });
