import { ContentUser, contentUserSchema } from './user.types';
import { Asset, assetSchema } from './asset.types';
import { Task } from './task.types';
import { z } from 'zod';
import { cameraCoordinatesSchema, sceneLightningSchema } from './threeDimensions.types';

export const commentSchema = z.object({
    uuid: z.string(),
    content: z.string(),
    draft: z.boolean(),
    isImportant: z.boolean(),
    created: z.date(),
    updated: z.date().nullable(),
    tags: z.array(z.string()),
    postedAs: z.string().nullable(),
    user: z.union([
        z.object({
            email: z.string(),
            uuid: z.string().optional()
        }),
        contentUserSchema
    ]),
    assets: z.array(assetSchema),
    note: z.object({
        uuid: z.string()
    }),
    file: z.object({
        uuid: z.string(),
        name: z.string(),
        version: z.number()
    })
});

export type Comment = {
    uuid: string;
    content: string;
    draft: boolean;
    created: Date;
    updated: Date | null;
    postedAs?: string | null;
    user: ContentUser | { email: string; uuid?: undefined };
    assets: Asset[];
    isImportant?: boolean;
    tags?: string[];
    note: { uuid: string };
    file: {
        uuid: string;
        name: string;
        version: number;
    };
    castFileUuid?: string | null;
};

export type Note = {
    uuid: string;
    created: Date;
    updated: Date | null;
    type: string;
    metadata: NoteMetadata;
    assets: Asset[];
    user:
        | ContentUser
        | { uuid?: undefined; lastname?: undefined; firstname?: undefined; email: string }
        | null;
    task: Omit<Task, 'note' | 'client' | 'assets'> | null;
    team: {
        name: string;
        uuid: string;
        color: string;
    } | null;
    comments: Omit<Comment, 'note' | 'file'>[];
    file: {
        uuid: string;
        name: string;
        version: number;
    };
    cast?: {
        uuid: string;
        name: string;
    } | null;
    castFileUuid?: string | null;
};

export const noteMetadataSchema = z.object({
    freehand: z.array(z.array(z.object({ x: z.number(), y: z.number() }))).optional(),
    activeAudioTrack: z.number().optional(),
    activeSubtitles: z.array(z.string()).optional(),
    circle: z
        .array(
            z.object({
                x: z.number(),
                y: z.number(),
                radiusToWidth: z.number(),
                radiusToHeight: z.number()
            })
        )
        .optional(),
    original: z
        .object({
            ImageHeight: z.union([z.string(), z.number()]),
            ImageWidth: z.union([z.string(), z.number()]),
            height: z.number().optional(),
            width: z.number().optional()
        })
        .optional(),
    page: z.number().optional(),
    rectangle: z
        .object({
            x: z.number(),
            y: z.number(),
            width: z.number(),
            height: z.number()
        })
        .optional(),
    camera: cameraCoordinatesSchema.optional(),
    lightning: sceneLightningSchema.optional(),
    tcIn: z.string().optional(),
    tcOut: z.string().optional(),
    frame: z.number().optional()
});

export type NoteMetadata = z.infer<typeof noteMetadataSchema>;

export type NoteSorting = 'created' | 'timecode' | 'page';
export type NoteSorter = { value: NoteSorting; label: string };
