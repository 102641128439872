import { z } from 'zod';
import { AssetStatus } from '../db/enums';
import { sanitizedString } from '@he-novation/utils/zod.utils';
import { workspaceParamsSchema } from './workspace.payload';

export const assetUuidSchema = workspaceParamsSchema.extend({
    assetUuid: z.string().uuid()
});
export type AssetUuidSchema = z.infer<typeof assetUuidSchema>;

export const assetStatusBody = z.object({
    status: z.nativeEnum(AssetStatus),
    signUrl: z.boolean().optional()
});

export type AssetStatusBody = z.infer<typeof assetStatusBody>;

export const baseUploadPayload = z.object({
    name: sanitizedString(),
    size: z.number(),
    mimeType: sanitizedString()
});

export type BaseUploadPayload = z.infer<typeof baseUploadPayload>;

export const assetUploadLinkBody = z.object({
    size: z.number(),
    contentType: z.string(),
    fileName: sanitizedString(),
    key: z.string()
});

export type AssetUploadLinkBody = z.infer<typeof assetUploadLinkBody>;

export const assetUploadCommandParam = z.object({
    assetUuid: z.string().uuid()
});
export type AssetUploadCommandParam = z.infer<typeof assetUploadCommandParam>;

export const assetUploadAbortBody = z.object({
    uploadId: z.string()
});

export type AssetUploadAbortBody = z.infer<typeof assetUploadAbortBody>;

export const assetUploadCompleteBody = z.object({
    uploadId: z.string(),
    parts: z.array(
        z.object({
            ETag: z.string(),
            PartNumber: z.number(),
            ChecksumCRC32: z.string().optional(),
            ChecksumCRC32C: z.string().optional(),
            ChecksumSHA1: z.string().optional(),
            ChecksumSHA256: z.string().optional()
        })
    )
});

export type AssetUploadCompleteBody = z.infer<typeof assetUploadCompleteBody>;

export const migrateFolderAssetsBody = z.object({
    folderUuid: z.string().uuid(),
    bucketName: z.string()
});

export type MigrateFolderAssetsBody = z.infer<typeof migrateFolderAssetsBody>;
